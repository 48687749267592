import { call, put, takeLatest } from 'redux-saga/effects'
import { omit, pick } from 'lodash'

import { getDayOneApi } from '@common/net'
import { transformEmptyStringsToNull } from '@helpers/utils'
import { createSaga } from '@common/sagaCreator/createSaga'
import { parseOwner } from '@shared/Grid/utils/ownerCellUtils'
import * as constants from '@dayOne/actions/actionTypes'
import { isEmpty } from 'lodash/fp'
import { parseOwners } from '@shared/Grid/CellEditors/utils'
import { getRowNodeIdProcessItem } from '@shared/DayOne/utils/mapDayOneData'
import { setSelectedProcessId } from '@dayOne/actions/actions'
import { openNotificationWithIcon } from '@imo/imo-ui-toolkit'
import { refreshPageDialogParams } from '../../utils'

export const createTask = createSaga(function* createTask(action: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data: taskData, onSuccess, onFailure, disableLoadingOverlay } = action.payload
  try {
    const dayOneApi = yield getDayOneApi()
    const createdData = yield call(
      dayOneApi.request('createTask', {
        query: {
          teamId: teamId,
          keyProcessId: taskData.keyProcessId,
          projectId: taskData.projectId,
        },
        body: pick(taskData, ['name', 'confidential']),
      }),
    )

    yield disableLoadingOverlay()

    const rowNodeId = getRowNodeIdProcessItem({ ...taskData, ...createdData, teamId, rowId: undefined })

    yield put(setSelectedProcessId(rowNodeId))

    if (onSuccess) yield call(onSuccess)
  } catch (e) {
    if (onFailure) yield call(onFailure)

    throw e
  }
})

export const updateTask = createSaga(function* updateTask(action: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const {
    teamId,
    data: taskData,
    onSuccess,
    onFailure,
    optimisticUpdate = true,
    isDueDataChanged = false,
  } = action.payload

  try {
    if (onSuccess && optimisticUpdate) yield call(onSuccess)

    const dayOneApi = yield getDayOneApi()
    const responseData = yield call(
      dayOneApi.request('updateTask', {
        query: {
          teamId: teamId,
          keyProcessId: taskData.keyProcessId,
          projectId: taskData.projectId,
          taskId: taskData.id,
        },
        body: transformEmptyStringsToNull({
          ...omit(taskData, [
            'hierarchy',
            'timeFlag',
            'type',
            'id',
            'index',
            'projectId',
            'keyProcessId',
            'attachments',
            'keyProcessId',
            'interdependency',
            'isIncoming',
            'isOutgoing',
            'longTeamName',
            'primaryIMO',
            'teamId',
            'ancestor',
            'linkedInitiative',
            'linkedOneTimeCost',
            'teamKeyProcessL2Id',
            'project',
            'taskId',
            'customColumns',
            'libraryFunctionId',
          ]),
          owner: parseOwner(taskData.owner),
          teamMembers: isEmpty(taskData.teamMembers) ? null : parseOwners(taskData.teamMembers),
        }),
      }),
    )

    if (onSuccess && !optimisticUpdate) yield call(onSuccess)

    if (onSuccess && isDueDataChanged) {
      const successPayload = {
        forecastDate: responseData.forecastDate,
        isDueDataChanged,
      }

      yield call(onSuccess, { payload: successPayload })
    }
  } catch (e) {
    const { status } = e as $TSFixMe
    if (status === 404) {
      openNotificationWithIcon(refreshPageDialogParams)

      return
    }

    if (onFailure) yield call(onFailure)

    throw e
  }
}, true)

export const deleteTask = createSaga(function* deleteTask(action: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data: taskData, onSuccess } = action.payload
  const dayOneApi = yield getDayOneApi()
  try {
    yield call(
      dayOneApi.request('deleteTask', {
        query: {
          teamId: teamId,
          keyProcessId: taskData.keyProcessId,
          projectId: taskData.projectId,
          taskId: taskData.id,
        },
      }),
    )

    if (onSuccess) yield call(onSuccess)
  } catch (e) {
    const { status } = e as $TSFixMe
    if (status === 404) {
      openNotificationWithIcon(refreshPageDialogParams)

      return
    }

    throw e
  }
})

export default [
  takeLatest(constants.CREATE_TASK, createTask),
  takeLatest(constants.UPDATE_TASK, updateTask),
  takeLatest(constants.DELETE_TASK, deleteTask),
]
