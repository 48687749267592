// Libs
import { isString } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Position } from '@blueprintjs/core'

// Components
import { FestiveLogo, Logo } from '@shared/Logo'
import ButtonsList from './ButtonsList'
import { LogoButton, MainMenu, StyledSider, UserMenu } from './index.styles'
import LogoTooltip from './LogoTooltip/LogoTooltip'

// Selectors
import {
  getIsPdfEnabled,
  getPermissions,
  getRoles,
  hasCentIntTeams,
  hasUserFullAdminPermissions,
  isChatOpen,
  isDocsBotOpen,
  isSupportBotOpen,
  userInfo as selectUserInfo,
} from '@generic/selectors'
import { checkIsRestricted, hasRestrictedRole } from '@generic/selectors/utils'
import { useQuery as useQueryParams } from '@helpers/utils'
import { isModuleDisabled } from '@common/accessController/moduleAccess'
import { logout, setSelectedTeamId, toggleChat, toggleDocsBot, toggleSupportBot } from '@generic/actions/actions'
import { isAnyConfigStepResubmitted } from '@domain/myImoConfig/selectors'

// Utils
import { mainMenuData, userMenuData } from './MenuService'
import { routesList } from '@common/accessController/routesList'
import { CustomTooltip } from '@shared/Popover'
import usePermissionsChecker from '@common/accessController/hooks/usePermissionsChecker'
import { getVCTrackerChecker } from '@common/accessController/strategies/vcTracker'
import { sourceDeliverables } from '@helpers/constants'
import { REPORTERS_PAGES } from '@common/accessController/utils'
import { getIsLaunched, getManagementType, getModules, getSupportEmail } from '@domain/instanceConfig/selectors'
import { useFeatureFlags } from '@hooks/useFeatureFlags'
import { ProductIdentifier, useUserInfo } from '@imo/chat'
import { useLogDiagnostics } from './useLogDiagnostics'
import moment from 'moment'
import { CustomHeapEvent, getHeapAnalytics } from '@common/heap'
import { useIsAskMyIMOModuleEnabled } from '@views/hooks/useIsAskMyIMOModuleEnabled'
import { useLocation, useNavigate } from 'react-router-dom'
import { useImportantNotifications } from './useImportantNotifications'

interface Props {
  mid?: $TSFixMe
}

const Sider = ({ mid }: Props) => {
  const userInfo = useSelector(selectUserInfo)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const permissions = useSelector(getPermissions)
  const isPdfEnabled = useSelector(getIsPdfEnabled)
  const hasReporterTeams = useSelector(hasCentIntTeams)
  const fromTeam = useQueryParams(location.search).get(sourceDeliverables.FROM_TEAM)
  const modules = useSelector(getModules)
  const roles = useSelector(getRoles)
  const isLaunched = useSelector(getIsLaunched)
  const managementType = useSelector(getManagementType)
  const supportEmail = useSelector(getSupportEmail)
  const hasUserAdminPermission = useSelector(hasUserFullAdminPermissions)
  const hasAnyStepResubmitted = useSelector(isAnyConfigStepResubmitted)
  const { checkPermission } = usePermissionsChecker(getVCTrackerChecker)
  const { isEnabled } = useFeatureFlags()
  const diagnostics = useLogDiagnostics()
  const heap = getHeapAnalytics()

  const isOpen = useSelector(isChatOpen)
  const { hasAccess } = useUserInfo()
  const isAskMyImoModuleEnabled = useIsAskMyIMOModuleEnabled()
  const isChatEnabled = isAskMyImoModuleEnabled && hasAccess(ProductIdentifier.ASK_MYIMO)
  const isSupportBotEnabled =
    isEnabled('ASK_MYIMO_SUPPORT_BOT') && isAskMyImoModuleEnabled && hasAccess(ProductIdentifier.SUPPORT_BOT)
  const isSupportOpen = useSelector(isSupportBotOpen)
  const isDocsOpen = useSelector(isDocsBotOpen)

  const handleLogout = () => {
    dispatch(logout({ isUserLogout: true }))
  }

  const isValueCaptureAvailable = checkPermission()

  const isMenuItemActive = (path: $TSFixMe) => {
    const { USER_MANAGEMENT, CONFIG } = routesList

    const isUserManagementPage = USER_MANAGEMENT.route === location.pathname

    const isUserManagementMenuItem = path === USER_MANAGEMENT.route

    if (isUserManagementPage && isUserManagementMenuItem) {
      return true
    }

    if (isUserManagementPage && path === CONFIG.route) {
      return false
    }

    return !!(path.length && `/${location.pathname.split('/')[1]}` === path)
  }

  const hasPermissions = (pageName: $TSFixMe) => {
    return (permissions as $TSFixMe).some((permission: $TSFixMe) => {
      if (hasRestrictedRole(roles) && pageName === 'My Team') {
        return true
      }

      return permission.route.title === pageName.toLowerCase()
    })
  }

  const isMenuItemDisabled = (pageName: $TSFixMe) => {
    if (!isString(pageName)) {
      return false
    }

    if (
      isLaunched &&
      pageName === 'Executive Dashboard' &&
      roles.some(
        ({ teamType }: { teamType: string; role: string }) => teamType.includes('IMO') || teamType.includes('SMO'),
      )
    ) {
      return false
    }

    if (!isLaunched && pageName !== 'Admin Config' && pageName !== 'User Management') {
      return true
    }

    const isModuleEnabled = !isModuleDisabled(pageName, modules)
    const isOnlyRestrictedUser = roles.every(({ role }: { role: string }) => checkIsRestricted(role))

    if (pageName === 'Notifications Hub' && !isOnlyRestrictedUser && isEnabled('NOTIFICATIONS_HUB')) {
      return false
    }

    if (pageName === 'Value Capture Tracker') {
      return !isModuleEnabled || !isValueCaptureAvailable
    }

    if (REPORTERS_PAGES.includes(pageName.toLowerCase()) && !hasReporterTeams) return true

    return !isModuleEnabled || !hasPermissions(pageName)
  }

  const shouldMenuItemBeShown = (pageName: string) => {
    if (!isString(pageName)) return false

    const isModuleDisabledInDB = isModuleDisabled(pageName, modules)

    if (pageName === 'Org Design Tracker') {
      return !isModuleDisabledInDB && isLaunched
    }

    if (pageName === 'Executive Dashboard') {
      return !isModuleDisabledInDB
    }

    return true
  }

  const isMenuItemShown = (pageName: $TSFixMe) => {
    return pageName !== 'User Management' || hasUserAdminPermission
  }

  const isMenuItemHasError = (pageName: $TSFixMe) => {
    return pageName === 'Admin Config' && hasAnyStepResubmitted
  }

  const goToPage = (path: $TSFixMe) => {
    if (!path) return

    if (isOpen) {
      dispatch(toggleChat())
    }

    if (isSupportOpen) {
      dispatch(toggleSupportBot())
    }

    if (isDocsOpen) {
      dispatch(toggleDocsBot())
    }

    // go to previously selected central team if we have fromTeam param
    if (fromTeam) {
      dispatch(setSelectedTeamId({ team: parseInt(fromTeam) }))
    }

    navigate(path)
  }

  const isOnlyRestrictedUser = roles.every(({ role }: { role: string }) => checkIsRestricted(role))
  const { data } = useImportantNotifications(isEnabled('NOTIFICATIONS_HUB') && !isOnlyRestrictedUser)

  const toggleSupport = () => {
    if (isOpen) {
      dispatch(toggleChat())
    }
    if (isDocsOpen) {
      dispatch(toggleDocsBot())
    }
    dispatch(toggleSupportBot())
  }

  const menuData = userMenuData({
    mid,
    userInfo,
    onLogout: handleLogout,
    importantNotifications: data,
    managementType,
    supportEmail,
    isSupportBotEnabled,
    toggleSupportBot: toggleSupport,
    ...diagnostics,
  })

  if (isPdfEnabled) {
    return null
  }

  const toggleAsk = () => {
    heap?.track(isOpen ? CustomHeapEvent.AskClosed : CustomHeapEvent.AskOpened)
    if (isSupportOpen) {
      dispatch(toggleSupportBot())
    }
    if (isDocsOpen) {
      dispatch(toggleDocsBot())
    }
    dispatch(toggleChat())
  }

  const startFestivePeriod = new Date('2024-12-01T00:00:00.000Z')
  const endFestivePeriod = new Date('2025-01-01T00:00:00.000Z')
  const isFestivePeriod = moment().isBetween(startFestivePeriod, endFestivePeriod)

  return (
    <StyledSider collapsible collapsed={true} role="menubar">
      {isChatEnabled ? (
        <LogoButton highlighted={isOpen} onClick={toggleAsk} data-testid="myimo-logo-button">
          {isFestivePeriod ? (
            <FestiveLogo data-testid="myimo-logo" tabIndex={-1} />
          ) : (
            <Logo data-testid="myimo-logo" tabIndex={-1} />
          )}
        </LogoButton>
      ) : (
        <CustomTooltip content={<LogoTooltip managementType={managementType} />} placement={Position.RIGHT}>
          {isFestivePeriod ? (
            <FestiveLogo data-testid="myimo-logo" tabIndex={-1} />
          ) : (
            <Logo data-testid="myimo-logo" tabIndex={-1} />
          )}
        </CustomTooltip>
      )}
      <MainMenu>
        <ButtonsList
          menuData={mainMenuData(managementType)}
          isActive={(item) => isMenuItemActive(item.path)}
          isDisabled={isMenuItemDisabled}
          isShown={shouldMenuItemBeShown}
          onClick={(item) => goToPage(item.path)}
          keyPrefix={'mainMenu'}
        />
      </MainMenu>
      <UserMenu>
        <ButtonsList
          menuData={menuData}
          isActive={(item) => {
            if (item.id === 'tutorial' && isSupportBotEnabled) {
              return isSupportOpen
            }
            return isMenuItemActive(item.path)
          }}
          isDisabled={isMenuItemDisabled}
          isHasError={isMenuItemHasError}
          onClick={(item) => goToPage(item.path)}
          keyPrefix={'userMenu'}
          isShown={isMenuItemShown}
        />
      </UserMenu>
    </StyledSider>
  )
}

Sider.defaultProps = {
  mid: {},
}

export default Sider
