import React from 'react'
import RegularSpinner from '@assets/svg/spinner_regular.svg'

interface SpinnerProps {
  size?: number
}

const Spinner: React.FC<SpinnerProps> = ({ size = 100 }) => {
  return (
    <div className="spinner">
      <img
        src={RegularSpinner}
        alt="Loading spinner"
        style={{
          width: `${size}px`,
          height: 'auto',
        }}
      />
    </div>
  )
}

export default Spinner
