import { find } from 'lodash'
import { handleActions } from 'redux-actions'
import * as constants from '../actions/actionTypes'
import { flattenAssumptionsFromLibrary } from '@myImoClient/components/Deliverables/TeamCharter/AddLibraryPopup/utils'
import { updateKeyProcessData, updateKeyProcessIdData, makeItemsDisabled, teamCharterTypeKeys } from '../helpers'

export interface ITeamCharterState {
  teamLeads: string
  sponsors: string
  assumptions: $TSFixMe
  inScopeKeyProcesses: $TSFixMe[]
  outScopeProcesses: $TSFixMe[]
  keyInterdependency: $TSFixMe[]
  keyRiskToMitigate: $TSFixMe[]
  keyDecisionToMake: $TSFixMe[]
  macroPlanningAssumption: $TSFixMe[]
  teamMembers: $TSFixMe[]
  teamCharterLibraryData: {
    assumptions: $TSFixMe[]
    decisions: $TSFixMe[]
    inScopeProcesses: $TSFixMe[]
    keyInterdependencies: $TSFixMe[]
    meta: $TSFixMe[]
    outScopeProcesses: $TSFixMe[]
    risks: $TSFixMe[]
  }
}

export const initialState = {
  teamLeads: '',
  sponsors: '',
  assumptions: {},
  inScopeKeyProcesses: [],
  outScopeProcesses: [],
  keyInterdependency: [],
  keyRiskToMitigate: [],
  keyDecisionToMake: [],
  macroPlanningAssumption: [],
  teamMembers: [],
  //TODO: Why we need this property? Could we get rid of it?
  teamCharterLibraryData: {
    assumptions: [],
    decisions: [],
    inScopeProcesses: [],
    keyInterdependencies: [],
    meta: [],
    outScopeProcesses: [],
    risks: [],
  },
}

const teamCharterReducer = handleActions<ITeamCharterState, $TSFixMe>(
  {
    [constants.SET_TEAM_CHARTER_DATA]: (state, action) => ({
      ...state,
      ...action.payload,
    }),

    [constants.UPDATE_TEAM_CHARTER_DATA]: (state, action) => ({
      ...state,
      ...action.payload,
    }),

    [constants.SET_IN_SCOPE_KEY_PROCESSES]: (state, { payload: inScopeKeyProcesses }) => {
      return {
        ...state,
        inScopeKeyProcesses,
      }
    },

    [constants.CREATE_IN_SCOPE_KEY_PROCESS_SUCCESS]: (state, { payload }) => {
      const { inScopeKeyProcesses } = state

      return {
        ...state,
        inScopeKeyProcesses: [...inScopeKeyProcesses, payload],
      }
    },

    [constants.SET_UPDATE_IN_SCOPE_KEY_PROCESS]: (state, action) => {
      const { data, id } = action.payload

      return {
        ...state,
        inScopeKeyProcesses: updateKeyProcessData(state.inScopeKeyProcesses, data, id),
      }
    },

    [constants.DELETE_IN_SCOPE_KEY_PROCESS_SUCCESS]: (state, action) => {
      const { inScopeKeyProcesses } = state
      const { id } = action.payload

      return {
        ...state,
        inScopeKeyProcesses: inScopeKeyProcesses.filter((item) => item.id !== id),
      }
    },

    [constants.SET_OUT_SCOPE_KEY_PROCESSES]: (state, { payload: outScopeProcesses }) => {
      return {
        ...state,
        outScopeProcesses,
      }
    },

    [constants.ADD_OUT_SCOPE_KEY_PROCESS]: (state, action) => {
      const { outScopeProcesses } = state

      return {
        ...state,
        outScopeProcesses: [...outScopeProcesses, action.payload],
      }
    },

    [constants.SET_UPDATE_OUT_SCOPE_KEY_PROCESS]: (state, action) => {
      const { data, keyProcessId } = action.payload

      return {
        ...state,
        outScopeProcesses: updateKeyProcessIdData(state.outScopeProcesses, data, keyProcessId),
      }
    },

    [constants.REMOVE_OUT_SCOPE_KEY_PROCESS]: (state, action) => {
      const { outScopeProcesses } = state
      const { keyProcessId } = action.payload

      return {
        ...state,
        outScopeProcesses: outScopeProcesses.filter((item) => item.keyProcessId !== keyProcessId),
      }
    },

    [constants.SET_MACRO_PLANNING_ASSUMPTION]: (state, { payload: treeDataMacroPlanningAssumption }) => {
      return {
        ...state,
        macroPlanningAssumption: treeDataMacroPlanningAssumption,
      }
    },

    [constants.ADD_MACRO_PLANNING_ASSUMPTION]: (state, action) => {
      const { macroPlanningAssumption } = state
      const { payload } = action
      const parentAssumption = macroPlanningAssumption.find((assumption) => assumption.id === payload.parentId)
      const newAssumption = {
        ...payload,
        hierarchy: payload.parentId ? [parentAssumption.name, payload.name] : [payload.name],
      }

      return { ...state, macroPlanningAssumption: [...macroPlanningAssumption, newAssumption] }
    },

    [constants.SET_UPDATE_MACRO_PLANNING_ASSUMPTION]: (state, { payload }) => {
      const { macroPlanningAssumption } = state
      const { assumptionData, id, parentId } = payload
      const parentAssumption = macroPlanningAssumption.find((assumption) => assumption.id === parentId)
      const updatedAssumption = {
        hierarchy: parentAssumption ? [parentAssumption.name, assumptionData.name] : [assumptionData.name],
        ...assumptionData,
      }

      const updatedAssumptions = macroPlanningAssumption.map((assumption) => {
        if (assumption.id === id) {
          return {
            ...assumption,
            ...updatedAssumption,
            isNew: false,
          }
        }

        if (!parentAssumption && assumption.parentId === id) {
          return {
            ...assumption,
            hierarchy: [updatedAssumption.name, assumption.name],
          }
        }

        return assumption
      })

      return { ...state, macroPlanningAssumption: updatedAssumptions }
    },

    [constants.REMOVE_MACRO_PLANNING_ASSUMPTION]: (state, { payload: selectedRow }) => {
      const { macroPlanningAssumption } = state

      const filteredAssumptions = macroPlanningAssumption.filter((assumption) => {
        if (!selectedRow?.parentId) {
          return assumption.id !== selectedRow.id && assumption?.parentId !== selectedRow.id
        } else {
          return assumption.id !== selectedRow.id
        }
      })

      return {
        ...state,
        macroPlanningAssumption: filteredAssumptions,
      }
    },

    [constants.SET_KEY_INTERDEPENDENCIES]: (state, { payload: keyInterdependency }) => {
      return {
        ...state,
        keyInterdependency,
      }
    },

    [constants.ADD_KEY_INTERDEPENDENCY]: (state, action) => {
      const { keyInterdependency } = state

      return {
        ...state,
        keyInterdependency: [...keyInterdependency, action.payload],
      }
    },

    [constants.SET_UPDATE_KEY_INTERDEPENDENCY]: (state, action) => {
      const { data, id } = action.payload

      return {
        ...state,
        keyInterdependency: updateKeyProcessData(state.keyInterdependency, data, id),
      }
    },

    [constants.REMOVE_KEY_INTERDEPENDENCY]: (state, action) => {
      const { keyInterdependency } = state
      const { id } = action.payload

      return {
        ...state,
        keyInterdependency: keyInterdependency.filter((item) => item.id !== id),
      }
    },

    [constants.SET_KEY_RISK_TO_MITIGATE]: (state, { payload: keyRiskToMitigate }) => {
      return {
        ...state,
        keyRiskToMitigate,
      }
    },

    [constants.ADD_KEY_RISK_TO_MITIGATE]: (state, action) => {
      const { keyRiskToMitigate } = state

      return {
        ...state,
        keyRiskToMitigate: [...keyRiskToMitigate, action.payload],
      }
    },

    [constants.SET_UPDATE_KEY_RISK_TO_MITIGATE]: (state, action) => {
      const { data, id } = action.payload

      return {
        ...state,
        keyRiskToMitigate: updateKeyProcessData(state.keyRiskToMitigate, data, id),
      }
    },

    [constants.REMOVE_KEY_RISK_TO_MITIGATE]: (state, action) => {
      const { keyRiskToMitigate } = state
      const { id } = action.payload

      return {
        ...state,
        keyRiskToMitigate: keyRiskToMitigate.filter((item) => item.id !== id),
      }
    },

    [constants.SET_KEY_DECISION_TO_MAKE]: (state, { payload: keyDecisionToMake }) => {
      return {
        ...state,
        keyDecisionToMake,
      }
    },

    [constants.ADD_KEY_DECISION_TO_MAKE]: (state, action) => {
      const { keyDecisionToMake } = state

      return {
        ...state,
        keyDecisionToMake: [...keyDecisionToMake, action.payload],
      }
    },

    [constants.SET_UPDATE_KEY_DECISION_TO_MAKE]: (state, action) => {
      const { data, id } = action.payload

      return {
        ...state,
        keyDecisionToMake: updateKeyProcessData(state.keyDecisionToMake, data, id),
      }
    },

    [constants.REMOVE_KEY_DECISION_TO_MAKE]: (state, action) => {
      const { keyDecisionToMake } = state
      const { id } = action.payload

      return {
        ...state,
        keyDecisionToMake: keyDecisionToMake.filter((item) => item.id !== id),
      }
    },

    [constants.SET_TEAM_MEMBERS]: (state, { payload: teamMembers }) => {
      return {
        ...state,
        teamMembers,
      }
    },

    [constants.ADD_TEAM_MEMBER]: (state, action) => {
      const { teamMembers } = state

      return {
        ...state,
        teamMembers: [...teamMembers, action.payload],
      }
    },

    [constants.UPDATE_TEAM_MEMBER]: (state, action) => {
      const { data, id } = action.payload
      const updatedTeamMember = data
      const isTeamMemberExist = find(state.teamMembers, (member) => member.id === id)

      if (Boolean(isTeamMemberExist)) {
        return {
          ...state,
          teamMembers: updateKeyProcessData(state.teamMembers, updatedTeamMember, id),
        }
      }

      return {
        ...state,
        teamMembers: [...state.teamMembers, data],
      }
    },

    [constants.REMOVE_TEAM_MEMBER]: (state, action) => {
      const { teamMembers } = state
      const { id } = action.payload

      return {
        ...state,
        teamMembers: teamMembers.filter((item) => item.id !== id),
      }
    },
    [constants.SET_TEAM_CHARTER_LIBRARY_DATA]: (state, { payload: teamCharterLibraryData }) => {
      const { assumptions, ...restTeamCharterLibraryData } = teamCharterLibraryData

      return {
        ...state,
        teamCharterLibraryData: {
          ...makeItemsDisabled(restTeamCharterLibraryData),
          assumptions: flattenAssumptionsFromLibrary(assumptions),
        },
      }
    },
    [constants.FETCH_TEAM_CHARTER_LIBRARY_DATA]: (state) => {
      return {
        ...state,
        teamCharterLibraryData: {
          ...state.teamCharterLibraryData,
          assumptions: [],
        },
      }
    },
    [constants.UPDATE_LIBRARY_ASSUMPTIONS_GROUP]: (state, { payload }) => {
      const { rowNodeId, selected } = payload

      return {
        ...state,
        teamCharterLibraryData: {
          ...state.teamCharterLibraryData,
          assumptions: state.teamCharterLibraryData.assumptions.map((it) => {
            const rowId = it.parentAssumption ? `${it.parentAssumption.name}-${it.name}` : it.name

            return { ...it, selected: rowId === rowNodeId ? selected : it.selected }
          }),
        },
      }
    },
    [constants.UPDATE_TEAM_CHARTER_LIBRARY_DATA]: (state, { payload: { type, data } }) => {
      const defaultMapper = (it: $TSFixMe) => (it.name === data.name ? data : it)
      const assumptionsMapper = (it: $TSFixMe) => ({
        ...it,
        selected: data.groupName === it.name ? data.selected : it.selected,
        macroPlanningItems: it.macroPlanningItems.map((assumption: $TSFixMe) => {
          return assumption.name === data.name && data.groupName === it.name
            ? { ...data, selected: data.selected }
            : assumption
        }),
      })

      const interdependencyMapper = (it: $TSFixMe) => (it.name === data.name && it.team === data.team ? data : it)

      let mapper = defaultMapper
      if (type === teamCharterTypeKeys.assumptions) mapper = assumptionsMapper

      if (type === teamCharterTypeKeys.keyInterdependencies) mapper = interdependencyMapper

      return {
        ...state,
        teamCharterLibraryData: {
          ...state.teamCharterLibraryData,
          // @ts-expect-error check interface
          [type]: state.teamCharterLibraryData[type].map(mapper),
        },
      }
    },
  },
  initialState,
)

export default teamCharterReducer
