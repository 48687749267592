import { get, isNil } from 'lodash'
import { isIncomingKeyProcess, isKeyProcess, isProject, isTask } from '@shared/DayOne/utils/getProcessStatus'
import { getNewRowData } from '@shared/DayOne/utils/handleAddNewRow'
import { getProjectPlanNewRow } from '@shared/ProjectPlan/utils/getProjectPlanNewRow'
import { listItemTypes } from '@shared/DayOne/constants'

export const getContextMenuItems = ({
  params: { api, node, context },
  addItemAction,
  deleteAction,
  deleteDetailsData,
  projectPlan,
}: $TSFixMe) => {
  const data = get(node, ['data'], null)

  if (!node || !node.isSelected()) {
    api.deselectAll()
    !!node && node.setSelected(true)
  }

  const selectedRows = api.getSelectedRows()

  if (selectedRows?.length > 1)
    return [
      {
        name: 'Delete rows',
        icon: '<span class="bp4-icon bp4-icon-minus" data-cy="context-menu-delete-multiple-items" />',
        action: () => {
          deleteAction(selectedRows, api)
          deleteDetailsData(selectedRows)
        },
        cssClasses: ['contextMenuItem'],
      },
    ]

  const allChildrenCount = get(node, ['allChildrenCount'], null)
  const canCreateConfChildItems = context.checkPermission('confidential', data, context.dayOneData)
  const canCreateConfProjectItems = context.checkPermission('confidential', { type: 'project' }, context.dayOneData)
  const canCreateConfTaskItems = context.checkPermission('confidential', { type: 'task' }, context.dayOneData)
  let canCreateConfTaskForProject = false
  if (data?.type === listItemTypes.TASK) {
    const parentProject = context.dayOneData.find(
      (item: { id: number; hierarchy: string[] }) => item.hierarchy[1] === data.hierarchy[1],
    )
    canCreateConfTaskForProject = context.checkPermission('confidential', parentProject, context.dayOneData)
  }

  const addAction = (type: $TSFixMe, isConfidential = false) =>
    projectPlan
      ? addItemAction(getProjectPlanNewRow(type, data, api, isConfidential))
      : addItemAction(getNewRowData(type, data, isConfidential))

  const items = {
    keyProcess: {
      add: {
        name: 'Add key process',
        icon: '<span class="bp4-icon bp4-icon-plus" data-cy="context-menu-add-key-process" />',
        action: () => addAction(listItemTypes.KEY_PROCESS),
        cssClasses: ['contextMenuItem'],
      },
      remove: {
        name: 'Delete key process',
        icon: '<span class="bp4-icon bp4-icon-minus" data-cy="context-menu-delete-key-process" />',
        action: () => {
          deleteAction([data], api)
          deleteDetailsData([data])
        },
        cssClasses: ['contextMenuItem'],
      },
    },
    project: {
      add: {
        name: 'Add project',
        icon: '<span class="bp4-icon bp4-icon-plus" data-cy="context-menu-add-project" />',
        action: () => addAction(listItemTypes.PROJECT),
        cssClasses: ['contextMenuItem'],
      },
      addConf: {
        name: 'Add confidential project',
        icon: '<span class="bp4-icon bp4-icon-plus" data-cy="context-menu-add-project" />',
        action: () => addAction(listItemTypes.PROJECT, true),
        cssClasses: ['contextMenuItem'],
      },
      remove: {
        name: 'Delete project',
        icon: '<span class="bp4-icon bp4-icon-minus" data-cy="context-menu-delete-project" />',
        action: deleteAction.bind(null, [data], api),
        cssClasses: ['contextMenuItem'],
      },
    },
    task: {
      add: {
        name: 'Add task',
        icon: '<span class="bp4-icon bp4-icon-plus" data-cy="context-menu-add-task" />',
        action: () => addAction(listItemTypes.TASK),
        cssClasses: ['contextMenuItem'],
      },
      addConf: {
        name: 'Add confidential task',
        icon: '<span class="bp4-icon bp4-icon-plus" data-cy="context-menu-add-task" />',
        action: () => addAction(listItemTypes.TASK, true),
        cssClasses: ['contextMenuItem'],
      },
      remove: {
        name: 'Delete task',
        icon: '<span class="bp4-icon bp4-icon-minus" data-cy="context-menu-delete-task" />',
        action: deleteAction.bind(null, [data], api),
        cssClasses: ['contextMenuItem'],
      },
    },
  }

  const { keyProcess, project, task } = items

  if (isNil(data) || isIncomingKeyProcess(data)) {
    const { add } = keyProcess

    return [add]
  }

  if (isKeyProcess(data)) {
    const { add, remove } = keyProcess
    const { add: addProject, addConf: addConfProject } = project
    const options = [add, remove]

    if (!allChildrenCount) {
      options.push(addProject)
      if (canCreateConfProjectItems) {
        options.push(addConfProject)
      }
    }
    return options
  }

  if (isProject(data)) {
    const { add, addConf, remove } = project
    const { add: addTask, addConf: addConfTask } = task
    const options = [add]

    if (canCreateConfProjectItems) {
      options.push(addConf)
    }

    options.push(remove)

    if (!allChildrenCount) {
      options.push(addTask)
      if (canCreateConfChildItems) {
        options.push(addConfTask)
      }
    }

    return options
  }

  if (isTask(data)) {
    const { add, addConf, remove } = task
    const options = [add]

    if (canCreateConfTaskItems || canCreateConfTaskForProject) {
      options.push(addConf)
    }
    options.push(remove)
    return options
  }

  return []
}
