import React from 'react'
import { useFeatureFlags } from '@hooks/useFeatureFlags'
import { StyledGrid } from './ImoGrid.styles'
import LoaderLayerComponent from '@shared/LoaderLayerComponent'

export const ImoGrid = React.forwardRef((props: $TSFixMe, ref: $TSFixMe) => {
  const { isEnabled } = useFeatureFlags()
  const isEnhancedDayOneGridFilters = isEnabled('ENHANCED_DAY_ONE_GRID_FILTERS_STYLES')
  const loadingOverlayComponentParams = { size: 100 }

  return (
    <StyledGrid
      hideFilterInput={!isEnhancedDayOneGridFilters}
      {...props}
      ref={ref}
      $enhancedFilterVisuals={isEnhancedDayOneGridFilters}
      loadingOverlayComponent={LoaderLayerComponent}
      loadingOverlayComponentParams={loadingOverlayComponentParams}
    />
  )
})
