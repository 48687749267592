const prefix = '@tvc/initiativeList/'

export const FETCH_INITIATIVE = `${prefix}FETCH_INITIATIVE`

export const FETCH_INITIATIVE_LIST = `${prefix}FETCH_INITIATIVE_LIST`
export const FETCH_INFINITE_INITIATIVE_LIST = `${prefix}FETCH_INFINITE_INITIATIVE_LIST`
export const SET_INITIATIVE_LIST = `${prefix}SET_INITIATIVE_LIST`
export const ADD_INITIATIVE_TO_INITIATIVE_LIST = `${prefix}ADD_INITIATIVE_TO_INITIATIVE_LIST`
export const UPDATE_SELECTED_TEAM_AND_MIRRORED_INITIATIVES = `${prefix}UPDATE_SELECTED_TEAM_AND_MIRRORED_INITIATIVES`
export const UPDATE_SEVERAL_INITIATIVES = `${prefix}UPDATE_SEVERAL_INITIATIVES`
export const RESET_INITIATIVE_LIST = `${prefix}RESET_INITIATIVE_LIST`
export const FETCH_INITIATIVE_FILTERS_AND_LENGTH = `${prefix}FETCH_INITIATIVE_FILTERS_AND_LENGTH`
export const SET_INITIATIVE_FILTERS_AND_LENGTH = `${prefix}SET_INITIATIVE_FILTERS_AND_LENGTH`
export const FETCH_ALL_INITIATIVE_METADATA = `${prefix}FETCH_ALL_INITIATIVE_METADATA`
export const SET_ALL_INITIATIVE_METADATA = `${prefix}SET_ALL_INITIATIVE_METADATA`

export const SET_SELECTED_INITIATIVE_ID = `${prefix}SET_SELECTED_INITIATIVE_ID`
export const SET_TEAM_ID = `${prefix}SET_TEAM_ID`
export const SET_SYNERGY_TYPE = `${prefix}SET_SYNERGY_TYPE`

// Stage gate comments
export const FETCH_INITIATIVE_COMMENTS_LIST = `${prefix}FETCH_INITIATIVE_COMMENTS_LIST`
export const SET_INITIATIVE_COMMENTS_LIST = `${prefix}SET_INITIATIVE_COMMENTS_LIST`

export const FETCH_STAGE_GATE_VALIDATION_STATUS = `${prefix}FETCH_STAGE_GATE_VALIDATION_STATUS`
export const SET_STAGE_GATE_VALIDATION_STATUS = `${prefix}SET_STAGE_GATE_VALIDATION_STATUS`

export const CREATE_INITIATIVE_COMMENT = `${prefix}CREATE_INITIATIVE_COMMENT`
export const CREATE_INITIATIVE_COMMENT_SUCCESS = `${prefix}CREATE_INITIATIVE_COMMENT_SUCCESS`

export const UPDATE_STAGE_GATE = `${prefix}UPDATE_STAGE_GATE`
export const UPDATE_STAGE_GATE_SUCCESS = `${prefix}UPDATE_STAGE_GATE_SUCCESS`

export const CREATE_INITIATIVE = `${prefix}CREATE_INITIATIVE`
export const CREATE_INITIATIVE_SUCCESS = `${prefix}CREATE_INITIATIVE_SUCCESS`
export const CREATE_MIRROR_INITIATIVE_SUCCESS = `${prefix}CREATE_MIRROR_INITIATIVE_SUCCESS`
export const CREATE_INITIATIVE_SUCCESS_FOR_INFINITY_LIST = `${prefix}CREATE_INITIATIVE_SUCCESS_FOR_INFINITY_LIST`

export const UPDATE_INITIATIVE = `${prefix}UPDATE_INITIATIVE`
export const UPDATE_INITIATIVE_SUCCESS = `${prefix}UPDATE_INITIATIVE_SUCCESS`
export const SET_IS_ALL_INITIATIVE = `${prefix}SET_IS_ALL_INITIATIVE`

export const DELETE_INITIATIVE = `${prefix}DELETE_INITIATIVE`
export const DELETE_INITIATIVE_SUCCESS = `${prefix}DELETE_INITIATIVE_SUCCESS`

export const FETCH_FINANCIALS = `${prefix}FETCH_FINANCIALS`
export const SET_FINANCIALS = `${prefix}SET_FINANCIALS`
export const SET_FINANCIALS_FAIL = `${prefix}SET_FINANCIALS_FAIL`

export const UPDATE_FINANCIALS_PENDING = `${prefix}UPDATE_FINANCIALS_PENDING`
export const UPDATE_FINANCIALS_SUCCESS = `${prefix}UPDATE_FINANCIALS_SUCCESS`
export const UPDATE_FINANCIALS_FAIL = `${prefix}UPDATE_FINANCIALS_FAIL`

export const UPLOAD_INITIATIVE_ATTACHMENTS = `${prefix}UPLOAD_INITIATIVE_ATTACHMENTS`
export const FETCH_INITIATIVE_ATTACHMENTS = `${prefix}FETCH_INITIATIVE_ATTACHMENTS`
export const DELETE_INITIATIVE_ATTACHMENT = `${prefix}DELETE_INITIATIVE_ATTACHMENT`
export const UPLOAD_INITIATIVE_ATTACHMENT_SUCCESS = `${prefix}UPLOAD_INITIATIVE_ATTACHMENT_SUCCESS`
export const DELETE_INITIATIVE_ATTACHMENT_SUCCESS = `${prefix}DELETE_INITIATIVE_ATTACHMENT_SUCCESS`
export const POPULATE_INITIATIVE = `${prefix}POPULATE_INITIATIVE`

export const FETCH_INITIATIVES_CATEGORIES = `${prefix}FETCH_INITIATIVES_CATEGORIES`
export const SET_INITIATIVES_CATEGORIES = `${prefix}SET_INITIATIVES_CATEGORIES`

export const SET_ACTIVE_COST_FILTERS = `${prefix}SET_ACTIVE_COST_FILTERS`

export const SET_MIRROR_TEAMS = `${prefix}SET_MIRROR_TEAMS`
export const SET_MIRROR_EDIT_LOADING_STATE = `${prefix}SET_MIRROR_EDIT_LOADING_STATE`

export const GET_SUB_LINES_FOR_MIRRORING = `${prefix}GET_SUB_LINES_FOR_MIRRORING`
export const SET_SUB_LINES_FOR_MIRRORING = `${prefix}SET_SUB_LINES_FOR_MIRRORING`

export const SAVE_INITIATIVE_FETCH_PARAMS = `${prefix}SAVE_INITIATIVE_FETCH_PARAMS`
export const RESET_ALL_INITIATIVE_FILTERS = `${prefix}RESET_ALL_INITIATIVE_FILTERS`
