import styled from 'styled-components'

export const StyledLoader = styled.div<{ position: 'fixed' | 'absolute' }>`
  position: ${(props) => props.position};
  ${(props) =>
    props.position === 'absolute' &&
    `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`

export const LoaderText = styled.div`
  flex-direction: column;
  background: none;
  position: absolute !important;

  & > div {
    position: relative;
    z-index: 9999;
  }

  .bp4-spinner {
    margin-bottom: 10px;
  }
`
