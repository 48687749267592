import React from 'react'
import Loader from '@shared/Loader'
import { LoaderWrapper } from './index.styles'

interface WithLoaderProps {
  isLoading?: boolean
  children?: React.ReactNode
  size?: number
}

const LoaderLayerComponent: React.FC<WithLoaderProps> = ({ isLoading = true, children = undefined, size = 100 }) => {
  return isLoading ? (
    <LoaderWrapper>
      <Loader size={size} position="absolute" />
    </LoaderWrapper>
  ) : (
    <>{children}</>
  )
}

export default LoaderLayerComponent
