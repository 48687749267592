// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/slick-carousel/slick/slick.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/slick-carousel/slick/slick-theme.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/MckinseySans/McKinseySans-Italic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/MckinseySans/McKinseySans-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/MckinseySans/McKinseySans-LightItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/MckinseySans/McKinseySans-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/MckinseySans/McKinseySans-MediumItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/MckinseySans/McKinseySans-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./fonts/Bower/Bower-Bold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";

@font-face {
  font-family: 'MckinseySans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'MckinseySans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'MckinseySans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'MckinseySans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'MckinseySans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'MckinseySans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'BowerBold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff2');
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/fonts.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAIhB;EACE,2BAA2B;EAC3B,4DAA0E;EAC1E,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,4DAAyE;EACzE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,4DAA+E;EAC/E,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,4DAA0E;EAC1E,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,4DAAgF;EAChF,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,4DAA2E;EAC3E,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,4DAA0D;AAC5D","sourcesContent":["@charset \"UTF-8\";\n@import '~slick-carousel/slick/slick.css';\n@import '~slick-carousel/slick/slick-theme.css';\n\n@font-face {\n  font-family: 'MckinseySans';\n  src: url('./fonts/MckinseySans/McKinseySans-Italic.woff2') format('woff2');\n  font-style: italic;\n  font-weight: 400;\n}\n\n@font-face {\n  font-family: 'MckinseySans';\n  src: url('./fonts/MckinseySans/McKinseySans-Light.woff2') format('woff2');\n  font-style: normal;\n  font-weight: 300;\n}\n\n@font-face {\n  font-family: 'MckinseySans';\n  src: url('./fonts/MckinseySans/McKinseySans-LightItalic.woff2') format('woff2');\n  font-style: italic;\n  font-weight: 300;\n}\n\n@font-face {\n  font-family: 'MckinseySans';\n  src: url('./fonts/MckinseySans/McKinseySans-Medium.woff2') format('woff2');\n  font-style: normal;\n  font-weight: 500;\n}\n\n@font-face {\n  font-family: 'MckinseySans';\n  src: url('./fonts/MckinseySans/McKinseySans-MediumItalic.woff2') format('woff2');\n  font-style: italic;\n  font-weight: 500;\n}\n\n@font-face {\n  font-family: 'MckinseySans';\n  src: url('./fonts/MckinseySans/McKinseySans-Regular.woff2') format('woff2');\n  font-style: normal;\n  font-weight: 400;\n}\n\n@font-face {\n  font-family: 'BowerBold';\n  src: url('./fonts/Bower/Bower-Bold.woff2') format('woff2');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
