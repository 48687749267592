import React from 'react'
import { LoaderBackdrop } from './index.styles'

interface BackdropProps {
  isShown?: boolean
  position?: 'fixed' | 'absolute'
  children?: React.ReactNode
}

const Backdrop: React.FC<BackdropProps> = ({ isShown = true, position = 'fixed', children }) => (
  <LoaderBackdrop style={{ visibility: isShown ? 'visible' : 'hidden', position }} className="backdrop">
    {children}
  </LoaderBackdrop>
)

export default Backdrop
