import {
  isString,
  flow,
  find,
  get,
  negate,
  toLower,
  split,
  first,
  isEmpty,
  values,
  keys,
  reduce,
  getOr,
  pick,
  union,
  isArray,
  toNumber,
  identity,
  isNil,
  orderBy,
  get as getFp,
  filter,
  map,
  reject,
} from 'lodash/fp'
import { createSelector } from 'reselect'
import { getRestrictedTeams, getUserTeamsFromTree } from '@common/teamFilter/helpers'
import { getTeamParamsFromLocation } from '@common/teamFilter/locationUtils'
import { COLUMN_YEAR_COUNTER } from '@helpers/financialsConstants'
import {
  getSortedActiveUsersList,
  checkUserRoles,
  sortByName,
  isValueCaptureTeamAvailable,
  getLibraryGuidanceFilePath,
  filterAndDecorateColumns,
  isOrgDesignTeamAvailable,
} from './utils'
import * as teamConstants from '@generic/selectors/teamConstants'
import { getTeamTree, getReporters } from './teamTree'
import { CalendarViewOptions, coreModule, notSelectedOption } from '@helpers/constants'
import { transformToOptions, getSecondArg } from '@helpers/utils'
import { getFilteredLibraries, getLinkedTeams } from '@generic/selectors/getters'
import { getListOfYearsFromSelectedDate } from '@imo/imo-ui-toolkit'
import { teams, teamTitles } from '@common/accessController/constants'
import { getDateIgnoreTimezone, getMomentDateWithoutTimezone } from '@shared/Grid/utils/dates'
import { VCControllerIDs } from '@myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/TeamValueCaptureController/utils'
import { TeamTypeId } from '@common/constants'
import { getCalendarViewSelect, getFiscalYear, getVCTimelineYears } from '../../instanceConfig/selectors'
import { getFeatureFlags } from '@views/hooks/useFeatureFlags'
import moment from 'moment'

const isNotEmpty = negate(isEmpty)

export const getLocationPath = get(['generic', 'routerLocation', 'pathname'])
export const getLocationSearch = get(['generic', 'routerLocation', 'search'])
export const getLocationState = get(['generic', 'routerLocation', 'state'])
export const getLocationParams = createSelector(getLocationPath, getLocationSearch, getTeamParamsFromLocation)
export const clientState = get('generic')
export const configState = get('config')
export const getTenantId = get(['mid', 'tenant', 'id'])
export const getTenantName = get(['mid', 'tenant', 'name'])
export const getUsers = flow(clientState, get('users'))
export const users = flow(getUsers, sortByName)
export const getUsersListForLookup = flow(users, getSortedActiveUsersList)

// user-info selectors
export const userInfo = flow(clientState, get('user'))
export const getUserId = flow(userInfo, get('id'))
export const getIsFirstTimeUser = flow(userInfo, get('firstTime'))
export const getHasRestrictedDomain = flow(userInfo, get('hasRestrictedDomain'))
export const getIsAuthorized = flow(userInfo, isNotEmpty)
export const getStepSevenUsersList = createSelector(configState, getFp(['users', 'list']))

export const selectUsers = createSelector(
  (state) => state.generic.users,
  (users) => Object.values(users),
)

// teams
export const getUserTeams = flow(userInfo, get('teams'))
export const getUserRoles = flow(userInfo, get('roles'))

//deliverable-status
export const getDeliverableStatuses = flow(clientState, get('deliverableStatuses'))
export const getDeliverableStatusByImoTeamId = createSelector(
  getDeliverableStatuses,
  getSecondArg,
  (deliverablesStatus, teamId) => {
    const selectedDeliverable = find({ teamId: toNumber(teamId) }, deliverablesStatus)

    return {
      isAllProjectsTabLocked: selectedDeliverable?.isAllProjectsTabLocked,
      isAllTSAsTabLocked: selectedDeliverable?.isAllTSAsTabLocked,
      availableTSAsTeams: selectedDeliverable?.availableTSAsTeams,
    }
  },
)

// get all teams (including teams outside user's hierarchy)
export const allTeams = flow(clientState, get('teams'))
export const getGenericLoader = flow(clientState, get('loader'))
export const getAllTeams = flow(allTeams, identity)
export const getAllActiveNonIMOTeams = flow(
  allTeams,
  filter({ active: true }),
  reject((team) => ![TeamTypeId.INTEGRATION, TeamTypeId.CENTRAL].includes(team.teamTypeId)),
)

export const getKnowledgeCenterVisibilityStatusForTeam = createSelector(getAllTeams, getSecondArg, (teams, teamId) => {
  return getOr(false, 'isKnowledgeCenterActive', find({ id: Number(teamId) }, teams))
})

export const getProjectListSnapshotFirstDateForTeam = createSelector(getAllTeams, getSecondArg, (teams, teamId) => {
  return getOr(null, 'projectListSnapshotFirstDate', find({ id: Number(teamId) }, teams))
})

// get teams (Array<Team>) in user's hierarchy
export const getAllReporters = createSelector(getAllTeams, getReporters)

/*
  get teams (Object<Team>) in user's hierarchy grouped by IMO teams
  {
    "Reporter": {
      "EMEA IMO": [],
      "Germany IMO": [],
    }
    "IMO": [],
  }
*/
export const getTeamsTree = createSelector(getAllReporters, getTeamTree)

// check if user  has ValueCaptureCentralTeams available (based on classification field)
export const hasVCTeam = createSelector(getAllTeams, isValueCaptureTeamAvailable)
export const hasOrgDesignTeam = createSelector(getAllTeams, isOrgDesignTeamAvailable)

export const getTeamsWithoutVC = flow(clientState, get('teamsWithoutVC'))

export const getToggleSettings = createSelector(clientState, get('headerToggles'))

export const getMirroringInitiativesVisibilityStatusForTeam = createSelector(
  getToggleSettings,
  get(VCControllerIDs.MIRRORED_INITIATIVES),
)

export const getFteDashboardVisibilityStatus = createSelector(getToggleSettings, get(VCControllerIDs.FTE_DASHBOARD))

export const getSharedTargetsVisibilityStatus = createSelector(getToggleSettings, get(VCControllerIDs.SHARED_TARGET))

export const getConfidentialFilterValue = createSelector(getToggleSettings, get(VCControllerIDs.CONFIDENTIAL))

export const getLibraries = flow(clientState, get('libraries'))
export const getLibrariesV2 = flow(clientState, get('librariesV2'))
export const getFlatLibrariesWithTeams = createSelector(getLibrariesV2, getFilteredLibraries)
export const getAllTeamsWithLinkedLibrariesIds = createSelector(getLibrariesV2, getAllTeams, getLinkedTeams)
export const getLinkedLibrariesByTeam = flow(clientState, get('linkedLibraries'))
export const getLinkedGuidanceByKey = createSelector(getLinkedLibrariesByTeam, getSecondArg, getLibraryGuidanceFilePath)

export const getLibrariesForIntegration = flow(getLibrariesV2, get('integration'))
export const getLibrariesForCentral = flow(getLibrariesV2, get('central'))
/**
 * @deprecated. It will be deleted soon. Try using data from `src/domain/instanceConfig/selectors.ts`
 */
export const getConfig = flow(clientState, get('config'))
export const getCompanyLogos = flow(clientState, get('companyLogos'))
export const getCachedCompanyLogos = flow(clientState, get('cachedCompanyLogos'))
export const getAbortController = flow(clientState, get('abortController'))
export const getDayOneDate = flow(clientState, get(['config', 'dayOneDate']))

export const getIsLoadedInitialData = flow(clientState, get(['isLoadedInitialData']))
/**
 * @deprecated. It will be deleted soon. Try using data from `src/domain/instanceConfig/selectors.ts`
 * TODO: figure out possibility to get it from 'initiativeColumnsCustomNames'
 */
export const getNWCTitle = get([
  '@teamValueCaptureV2',
  'initiativeList',
  'initiativeColumnsCustomNames',
  'nwc',
  'impact',
])
export const getIsResetStarted = flow(clientState, get(['config', 'isResetStarted']))
export const getSelectedTeamId = flow(clientState, get(['selectedTeamIds', 'team']))
/**
 * @deprecated. It will be deleted soon. Try using data from `src/domain/instanceConfig/selectors.ts`
 */
const getCurrencyRounding = flow(getConfig, get('currencyRounding'))
/**
 * @deprecated. It will be deleted soon. Try using data from `src/domain/instanceConfig/selectors.ts`
 */
const getCurrency = flow(getConfig, ({ currency }) => (currency ? toLower(currency) : ''))
/**
 * @deprecated. It will be deleted soon. Try using data from `src/domain/instanceConfig/selectors.ts`
 */
export const getCustomCurrency = flow(getConfig, ({ currency }) => (currency ? toLower(currency) : null))
export const midState = get(['mid', 'mid', 'core', 'state'])

/**
 * @deprecated. Use useCurrency instead
 */
export const getCurrencyText = createSelector(getCurrency, getCurrencyRounding, (currency, rounding) => {
  const currencyText = currency && currency.toUpperCase()
  const currencyRounding = isString(rounding) && flow(split(' '), first, toLower)(rounding)

  return `${currencyText}, ${currencyRounding}`
})

export const getTeamsByType = createSelector(
  getAllTeams,
  (_, ...types) => types,
  (teams, teamTypes) => {
    return teams.filter(({ teamType }) => teamTypes.includes(teamType))
  },
)

export const getSortedTeamsByType = createSelector(getTeamsByType, (teams) =>
  orderBy((value) => value.longName?.toLowerCase(), ['asc'], teams),
)

export const getPermissions = flow(clientState, get('permissions'))
export const getMidCore = flow(midState, (data) => data?.get('MID'))
export const getAccessToken = createSelector(getMidCore, (mid) => mid.accessToken())
export const getMidData = createSelector(midState, (state) => state?.get('DATA'))
export const getMidLoginInfo = createSelector(getMidData, (data) => data?.loginInfo)
export const getAuthDriver = createSelector(getMidLoginInfo, (info) => info?.authDriver)
export const getAuthBaseDomain = createSelector(getMidLoginInfo, (info) => {
  const url = new URL(info?.domain)
  return `${url.protocol}//${url.hostname}`
})
export const getXTenant = get(['mid', 'tenant', 'id'])
export const getRole = flow(clientState, get('role'))
//TODO the same selector with getUserRoles
export const getRoles = flow(clientState, get('roles'))
export const getHasRole = flow(getRole, isNotEmpty)
export const getTeamType = flow(getRole, get('teamType'))

export const hasAnyTeams = flow(getAllTeams, isNotEmpty)
export const getTeamsPermissions = flow(clientState, get('teamsPermissions'))
export const getExceptions = flow(clientState, get('exceptions'))
export const getRedirects = flow(clientState, get('redirects'))
/**
 * @deprecated. It will be deleted soon. Try using data from `src/domain/instanceConfig/selectors.ts`
 */
const getModules = flow(getConfig, get('modules'))
export const getSnapshotMinDate = flow(clientState, ({ snapshotMinDate }) =>
  snapshotMinDate ? getMomentDateWithoutTimezone(snapshotMinDate) : getDateIgnoreTimezone(new Date()).toDate(),
)

export const hasReadOnlyRoleForTeam = createSelector([getRoles, (_, teamId) => teamId], (roles, teamId) => {
  if (!teamId || !roles) {
    return false
  }

  // Check directly user role on team.
  const homeTeam = roles.find((team) => team.id === teamId)
  if (homeTeam && !homeTeam.role.includes('read-only')) {
    return false
  }

  // Check global imo first
  const globalRole = roles.find((team) => team.teamType === teams.IMO_GLOBAL || team.teamType === teams.SMO_GLOBAL)

  if (globalRole && !globalRole.role.includes('read-only')) {
    return false
  }

  // Rest of IMO roles mustn't be read-only
  return roles
    .filter((team) => {
      return (
        (team.teamType === teams.IMO || team.teamType === teams.SMO) &&
        (team.reporterIds?.includes(teamId) || team.secondaryReporterIds?.includes(teamId))
      )
    })
    .every((team) => team.role.includes('read-only'))
})
export const hasUserAdminRole = createSelector(getUserRoles, checkUserRoles([teamConstants.USER_ADMIN]))
export const hasConfigAdminRole = createSelector(getUserRoles, checkUserRoles([teamConstants.CONFIG_ADMIN]))
export const hasUserFullAdminPermissions = createSelector(getUserRoles, checkUserRoles(teamConstants.ADMIN_ROLES))

export const hasOnlyUserAdminRole = createSelector(
  hasUserAdminRole,
  hasConfigAdminRole,
  getTeamType,
  (userAdmin, configAdmin, teamType) => !configAdmin && userAdmin && !Boolean(teamType),
)

export const hasOnlyConfigAdminRole = createSelector(
  hasUserAdminRole,
  hasConfigAdminRole,
  getTeamType,
  (userAdmin, configAdmin, teamType) => configAdmin && !userAdmin && !Boolean(teamType),
)

export const hasOnlyTeamRole = createSelector(
  hasUserAdminRole,
  hasConfigAdminRole,
  getTeamType,
  (userAdmin, configAdmin, teamType) => !configAdmin && !userAdmin && Boolean(teamType),
)

export const getTeamReportersById = createSelector(getAllTeams, getSecondArg, (allTeams, imoTeamId) =>
  flow(find({ id: imoTeamId }), pick(['reporterIds', 'secondaryReporterIds']), values, (values) => union(...values))(
    allTeams,
  ),
)

// TODO: cover with tests
export const getUserTeamsInfo = createSelector(getUserTeams, getAllTeams, (userTeams, allTeams) => {
  return userTeams.map((team) => ({ team, ...find({ id: team.id }, allTeams) }))
})

export const getImoAllReportersById = createSelector(getAllTeams, getTeamReportersById, (allTeams, teamReporterIds) => {
  if (isEmpty(teamReporterIds) || !isArray(teamReporterIds)) return allTeams

  return allTeams.filter(({ id, active }) => active && teamReporterIds.includes(id))
})

export const getRestrictedTeamIds = createSelector(getUserTeams, getTeamsTree, (userTeams, teamsTree) => {
  return getRestrictedTeams(getUserTeamsFromTree(userTeams, teamsTree[teams.REPORTER]))
})

export const getNotRestrictedTeamsTree = createSelector(
  getTeamsTree,
  (_, hideRestrictedTeams) => hideRestrictedTeams,
  (teamsTree, hideRestrictedTeams) => {
    if (!hideRestrictedTeams) return teamsTree

    return flow(
      keys,
      reduce((acc, key) => {
        if (teamsTree[key]?.length) acc[key] = teamsTree[key]

        return acc
      }, {}),
    )(teamsTree)
  },
)

export const hasCentIntTeams = createSelector(getTeamsTree, (tree) => !isEmpty(tree[teams.REPORTER]))

export const getExceptionUrls = createSelector(
  getTeamsPermissions,
  getExceptions,
  getSecondArg,
  (teamPermissions, exceptions, teamId) => {
    return teamId ? getOr([], [teamId, 'exceptions'], teamPermissions) : exceptions
  },
)

/**
 * @deprecated obsolete method. Should be deleted after refactor dependent selectors
 */
const getModuleIsActive = createSelector(
  getModules,
  (_, moduleName) => moduleName,
  (modules, moduleName) => getOr(false, [moduleName, 'active'], modules),
)

// TODO: refactor approach
export const getKnowledgeCenterAvailableForTeam = createSelector(
  (state) => getModuleIsActive(state, coreModule.KNOWLEDGE_CENTER),
  getKnowledgeCenterVisibilityStatusForTeam,
  hasConfigAdminRole,
  getHasRestrictedDomain,
  (isKCActive, isKCVisibleForTeam, isConfigAdmin, hasRestrictedDomain) => {
    return !hasRestrictedDomain && isKCActive && (isKCVisibleForTeam || isConfigAdmin)
  },
)

// TODO: refactor approach
export const getKnowledgeCenterAvailableForEdit = createSelector(
  (state) => getModuleIsActive(state, coreModule.KNOWLEDGE_CENTER),
  hasConfigAdminRole,
  getHasRestrictedDomain,
  (isKCActive, isConfigAdmin, hasRestrictedDomain) => {
    return !hasRestrictedDomain && isKCActive && isConfigAdmin
  },
)

export const getYearList = createSelector(
  getDayOneDate,
  getVCTimelineYears,
  getFiscalYear,
  getCalendarViewSelect,
  (dayOneDate, vcTimelineYears, fiscalYear, calendarView) => {
    const { VC_EXTENDED_TIMELINE } = getFeatureFlags()

    let financialsStartDate = dayOneDate

    if (
      calendarView === CalendarViewOptions.FiscalYear &&
      fiscalYear &&
      Number(fiscalYear) > new Date(dayOneDate).getMonth()
    ) {
      financialsStartDate = moment(dayOneDate).subtract(1, 'year').toDate()
    }

    if (!VC_EXTENDED_TIMELINE.enabled || !vcTimelineYears || Number.isNaN(Number(vcTimelineYears))) {
      return getListOfYearsFromSelectedDate(financialsStartDate, COLUMN_YEAR_COUNTER)
    }
    return getListOfYearsFromSelectedDate(financialsStartDate, Number(vcTimelineYears))
  },
)

export const getIsDayOneMissed = createSelector(getDayOneDate, (dayOneDate) => !Boolean(dayOneDate))

export const dictionaries = (state) => get('dictionaries', clientState(state))
/**
 * @type {() => import('../../myImoConfig/moduleOptions/reducer/types').DictionariesType} DictionariesType
 */
export const getDictionaries = flow(dictionaries, identity)

export const getProjectPlanDictionary = flow(getDictionaries, getOr([], 'projectPlan'))

const getProjectCategoryDictionary = flow(
  getProjectPlanDictionary,
  find((item) => item.name === 'category'),
  get('options'),
)

export const getPriorityDictionary = flow(
  getProjectPlanDictionary,
  find((item) => item.name === 'priority'),
  getOr([], 'options'),
)

export const getProjectCategories = createSelector(getProjectCategoryDictionary, transformToOptions)
export const getPriorityOptions = createSelector(getPriorityDictionary, transformToOptions)

export const getCustomColumns = createSelector(getDictionaries, getSecondArg, filterAndDecorateColumns)

export const getCustomColumnsOptions = createSelector(getDictionaries, getSecondArg, (dictionaries, module) => {
  return getOr([], module, dictionaries).reduce((accum, column) => {
    accum[column.name] = isNil(column.options) ? null : [notSelectedOption, ...transformToOptions(column.options)]

    return accum
  }, {})
})

export const getPriorityFilterOptions = createSelector(
  getPriorityOptions,
  getSecondArg,
  (options, specificPriorities) => [...specificPriorities, ...options],
)

export const getPrioritiesWithNotSelectedOptions = flow(getPriorityOptions, (options) => {
  return [notSelectedOption, ...options]
})

export const getLinkedLibrariesState = flow(clientState, get('linkedLibrariesState'))

export const getImoRelatedTeamsWithLinkedLibrariesIds = createSelector(
  getAllTeamsWithLinkedLibrariesIds,
  getImoAllReportersById,
  (allTeams, imoRelatedTeams) => imoRelatedTeams?.filter((team) => allTeams?.find((aTeam) => aTeam.id === team.id)),
)

export const getIsPdfEnabled = flow(clientState, get('isPdfEnabled'))
export const getInitiativeArgs = flow(clientState, get('initiativeArgs'))

//Action loading state
export const actionsState = flow(clientState, get('actionsState'))
export const getActionsState = flow(actionsState, identity)

export const getServerRespondingState = flow(clientState, get('isServerNotResponding'))

export const getSelectedTeamByTeamId = createSelector(getAllTeams, getSecondArg, (allTeams, teamId) =>
  allTeams?.find((team) => toNumber(team.id) === toNumber(teamId)),
)

export const getIntegrationUserRoles = flow(getUserTeams, filter({ teamType: teamTitles.INTEGRATION }), map('role'))

export const isChatOpen = createSelector(clientState, (client) => client.isChatOpen)
export const hasChatBeenOpened = createSelector(clientState, (client) => client.hasChatBeenOpened)
export const isSupportBotOpen = createSelector(clientState, (client) => client.isSupportBotOpen)
export const hasSupportBotBeenOpened = createSelector(clientState, (client) => client.hasSupportBotBeenOpened)
export const isDocsBotOpen = createSelector(clientState, (client) => client.isDocsBotOpen)
export const hasDocsBotBeenOpened = createSelector(clientState, (client) => client.hasDocsBotBeenOpened)

export const getRefreshedToken = createSelector(clientState, (client) => client.token)
