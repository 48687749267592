/* eslint-disable react/require-default-props */
import React, { Suspense } from 'react'
import { Provider, useSelector } from 'react-redux'
import { QueryClientProvider, QueryClient } from 'react-query'
import { GlobalStyle } from '../index.styles'
import { AuthProvider } from './auth-provider'
import { Store } from 'redux'
import FeatureFlagProvider from './feature-flag-provider'
import { RealTimeProvider } from '../domain/realTime/realTimeProvider'
import { RouterProvider, RouterProviderProps } from 'react-router-dom'
import { browserRouter } from '../routing/router/router'
import { getAccessToken, getRefreshedToken, getTenantId } from '../domain/generic/selectors'
import { useIsAskMyIMOModuleEnabled } from '../views/hooks/useIsAskMyIMOModuleEnabled'
import { ChatContextProvider } from '@imo/chat'
import Config from '../config'
import LoaderWithBackDrop from '@shared/LoaderWithBackDrop'

type AppProvidersProps = {
  store: Store
  queryClient: QueryClient
  router?: RouterProviderProps['router']
}

const AppWrapper = ({ children }: { children: React.ReactElement }) => {
  const isAskMyImoModuleEnabled = useIsAskMyIMOModuleEnabled()
  const initialToken = useSelector(getAccessToken)
  const refreshedToken = useSelector(getRefreshedToken)
  const activeToken = refreshedToken || initialToken
  const tenantId = useSelector(getTenantId)

  const Wrapper = () => <Suspense fallback={<LoaderWithBackDrop />}>{children}</Suspense>

  if (isAskMyImoModuleEnabled) {
    return (
      <ChatContextProvider
        proxyBaseURL={Config.ASK_WS_SERVER}
        apiBaseURL={Config.ASK_SERVER}
        tenantId={tenantId}
        token={activeToken}
      >
        <Wrapper />
      </ChatContextProvider>
    )
  }

  return <Wrapper />
}

export const AppProviders = ({ store, queryClient, router = browserRouter }: AppProvidersProps) => {
  const state = store.getState()
  const { mid } = state.mid

  return (
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <AuthProvider mid={mid}>
            <FeatureFlagProvider>
              <RealTimeProvider>
                <AppWrapper>
                  <RouterProvider router={router} />
                </AppWrapper>
              </RealTimeProvider>
            </FeatureFlagProvider>
          </AuthProvider>
        </Provider>
      </QueryClientProvider>
    </>
  )
}
