import React from 'react'
import { userRoles, teams } from '@common/accessController/constants'
import {
  getTeamType,
  hasOnlyUserAdminRole,
  hasOnlyConfigAdminRole,
  getIsFirstTimeUser,
  getConfig,
  hasOnlyTeamRole,
} from '@generic/selectors'
import { getIsLaunched, getModules } from '@domain/instanceConfig/selectors'
import { checkIsConfigUnset } from '@helpers/utils'
import { useSelector } from 'react-redux'
import Storage from '@helpers/storage'
import { Navigate } from 'react-router-dom'

const RouteSwitcher = () => {
  const teamType = useSelector(getTeamType)
  const isOnlyUserAdmin = useSelector(hasOnlyUserAdminRole)
  const isOnlyConfigAdmin = useSelector(hasOnlyConfigAdminRole)
  const isLaunchedInstance = useSelector(getIsLaunched)
  const isFirstTimeUser = useSelector(getIsFirstTimeUser)
  const config = useSelector(getConfig)

  const modules = useSelector(getModules)
  const isExecutiveDashboardAvailable = Boolean(modules.executiveDashboard?.active)

  const isConfigUnset = checkIsConfigUnset(config)
  const isOnlyTeamRoles = useSelector(hasOnlyTeamRole)

  if (isConfigUnset) return null

  if (isFirstTimeUser && isLaunchedInstance) {
    return <Navigate to={{ pathname: '/config/terms-conditions' }} />
  }

  if (isOnlyUserAdmin) {
    Storage.setItem('fromRoot', true)

    return <Navigate to={{ pathname: '/config/user-management' }} />
  }

  if (isOnlyConfigAdmin || (!isLaunchedInstance && !isOnlyTeamRoles)) {
    Storage.setItem('fromRoot', true)

    return <Navigate to={{ pathname: '/config' }} />
  }

  switch (teamType) {
    case userRoles.INSTANCE_ADMIN:
      return <Navigate to={{ pathname: '/config' }} />

    case teams.IMO_GLOBAL:
    case teams.SMO_GLOBAL:
    case teams.IMO:
    case teams.SMO:
      return isExecutiveDashboardAvailable ? (
        <Navigate to={{ pathname: '/executive-dashboard' }} />
      ) : (
        <Navigate to={{ pathname: '/imo-control-tower' }} />
      )

    default:
      return <Navigate to={{ pathname: '/dashboard' }} />
  }
}

export default RouteSwitcher
