import { get } from 'lodash'
import nanoid from 'nanoid'
import { listItemTypes } from '../constants'
import { isKeyProcess, isProject } from './getProcessStatus'

const newItemDefaultData = {
  name: '',
  isNew: true,
  custom: true,
  id: nanoid(),
  projectListId: '',
  predecessorId: null,
}

const newChildItemData = {
  ...newItemDefaultData,
  status: 'not started',
  priority: null,
  confidential: null,
  dueDate: null,
  forecastedDate: null,
}

const addKeyProcess = () => ({
  ...newItemDefaultData,
  required: false,
  type: listItemTypes.KEY_PROCESS,
})

const addProject = (selectedItem: $TSFixMe, isConfidential: boolean) => {
  const keyProcessId = isKeyProcess(selectedItem) ? get(selectedItem, 'id') : get(selectedItem, 'keyProcessId')

  return {
    ...newChildItemData,
    keyProcessId,
    type: listItemTypes.PROJECT,
    ...(isConfidential ? { confidential: true } : {}),
  }
}

const addTask = (selectedItem: $TSFixMe, isConfidential: boolean) => {
  const projectId = isProject(selectedItem) ? get(selectedItem, 'id') : get(selectedItem, 'projectId')

  return {
    ...newChildItemData,
    projectId,
    keyProcessId: get(selectedItem, 'keyProcessId'),
    type: listItemTypes.TASK,
    ...(isConfidential ? { confidential: true } : {}),
  }
}

export const getNewRowData = (itemType: $TSFixMe, selectedItem: $TSFixMe, isConfidential: boolean) => {
  switch (itemType) {
    case listItemTypes.KEY_PROCESS:
      return addKeyProcess()
    case listItemTypes.PROJECT:
      return addProject(selectedItem, isConfidential)
    case listItemTypes.TASK:
      return addTask(selectedItem, isConfidential)
    default:
      return null
  }
}
