import { handleActions, combineActions } from 'redux-actions'
import { get } from 'lodash'
import { assoc, merge, isEmpty, find, sortBy, flow, getOr, map } from 'lodash/fp'
import * as constants from '../actions/actionTypes'
import { updateItems } from '../selectors/utils'
import {
  getDefaultInitiativeData,
  getWithLinkedSynergyType,
  updateMirroredInitiative,
  updateMirroredInitiatives,
  updateInitiativeStageGate,
  getListAfterUpdate,
  sortAllInitiativeList,
} from './utils'
import { synergyTypes, loadingState, STAGE_GATE_IDS } from '@helpers/constants'
import { formatNewDate } from '@imo/imo-ui-toolkit'

export interface InitiativesColumnsCustomNames {
  cost?: { impact: string }
  nwc?: { impact: string }
  revenue?: { impact?: string; margin?: string; marginImpact?: string }
}

export interface IInitiativeListState {
  isAllInitiative?: boolean
  initiativeList: $TSFixMe[]
  filterState?: $TSFixMe | null
  lockedTabs?: $TSFixMe
  initiativeColumnsCustomNames?: InitiativesColumnsCustomNames
  initiativeCommentsList: $TSFixMe[]
  stageGateValidationStatus?: $TSFixMe
  financialsData?: $TSFixMe[]
  financialsDataLoading?: boolean
  initiativesCategories?: $TSFixMe
  selectedInitiativeId?: $TSFixMe | null
  teamId?: $TSFixMe | null
  synergyType?: $TSFixMe | null
  initiativeListLength?: number
  initiativeFilters?: $TSFixMe | null
  hasAttachments?: boolean
  activeCostFilters?: $TSFixMe
  mirrorEditLoadingState?: $TSFixMe
  initiativeFetchParams: {
    filters: $TSFixMe
    sorting: $TSFixMe[]
  }
  subLinesMirroring?: {
    netImpactItems: $TSFixMe[]
    mirroredInitiatives: $TSFixMe[]
  }
}

export const initialState = {
  isAllInitiative: false,
  initiativeList: [],
  lockedTabs: {},
  initiativeColumnsCustomNames: {},
  initiativeCommentsList: [],
  stageGateValidationStatus: {},
  financialsData: [],
  financialsDataLoading: false,
  initiativesCategories: {},
  selectedInitiativeId: null,
  teamId: null,
  synergyType: null,
  initiativeListLength: 0,
  initiativeFilters: null,
  hasAttachments: false,
  activeCostFilters: {},
  mirrorEditLoadingState: loadingState.INITIAL,
  initiativeFetchParams: {
    filters: {},
    sorting: [],
  },
  subLinesMirroring: {
    netImpactItems: [],
    mirroredInitiatives: [],
  },
}

const checkIsStageGateReady = (initiative: $TSFixMe, stageGateId: number) =>
  get(initiative, ['stageGate', 'id']) >= stageGateId

export const initiativeListReducer = handleActions<IInitiativeListState, $TSFixMe>(
  {
    [constants.RESET_INITIATIVE_LIST]: (state) => {
      return {
        ...state,
        selectedInitiativeId: null,
        initiativeList: [],
      }
    },
    [constants.SET_INITIATIVE_LIST]: (state, { payload }) => {
      const {
        list,
        initiativeColumnsCustomNames,
        lockedTabs,
        linkedInitiativeId,
        linkedSynergyTypeForOTC,
        deliverableInfo,
        companyAName,
      } = payload

      const newInitiative = {
        ...getDefaultInitiativeData(synergyTypes.ONE_TIME_COST, companyAName),
        linkedSynergyInitiative: linkedInitiativeId,
        linkedProject: {},
      }

      const filteredSynergyInitiatives = linkedSynergyTypeForOTC
        ? getWithLinkedSynergyType(list, linkedSynergyTypeForOTC)
        : list

      return {
        ...state,
        selectedInitiativeId: linkedInitiativeId || state.selectedInitiativeId,
        initiativeList: linkedInitiativeId
          ? [...filteredSynergyInitiatives, newInitiative]
          : filteredSynergyInitiatives,
        lockedTabs,
        initiativeColumnsCustomNames,
        deliverableInfo,
      }
    },
    [constants.ADD_INITIATIVE_TO_INITIATIVE_LIST]: (state, { payload }) => {
      const {
        list,
        initiativeColumnsCustomNames,
        lockedTabs,
        linkedInitiativeId,
        linkedSynergyTypeForOTC,
        locationState,
        deliverableInfo,
        companyAName,
      } = payload

      const previousInitiativeIds = []
      const filteredSynergyInitiatives = linkedSynergyTypeForOTC
        ? getWithLinkedSynergyType(list, linkedSynergyTypeForOTC)
        : list

      for (let i = 0; i < state.initiativeList.length; i++) previousInitiativeIds.push(state.initiativeList[i].id)

      let initiativeList

      if (linkedInitiativeId && state.initiativeList.length === 0) {
        initiativeList = [
          {
            ...getDefaultInitiativeData(synergyTypes.ONE_TIME_COST, companyAName, locationState),
            linkedSynergyInitiative: linkedInitiativeId,
            linkedProject: {},
            added: true,
          },
        ]
      } else {
        initiativeList = [...state.initiativeList]
      }

      for (let i = 0; i < filteredSynergyInitiatives.length; i++) {
        if (previousInitiativeIds.includes(filteredSynergyInitiatives[i].id)) continue

        initiativeList.push(filteredSynergyInitiatives[i])
      }

      return {
        ...state,
        selectedInitiativeId: linkedInitiativeId || state.selectedInitiativeId,
        initiativeList,
        lockedTabs,
        initiativeColumnsCustomNames,
        deliverableInfo,
      }
    },

    [constants.SET_ACTIVE_COST_FILTERS]: (state, action) => {
      return {
        ...state,
        activeCostFilters: action.payload,
      }
    },

    [constants.SET_SELECTED_INITIATIVE_ID]: (state, { payload }) => {
      return { ...state, selectedInitiativeId: payload }
    },

    [constants.SET_TEAM_ID]: (state, { payload }) => {
      return { ...state, teamId: payload }
    },

    [constants.SET_SYNERGY_TYPE]: (state, { payload }) => {
      return { ...state, synergyType: payload }
    },

    [constants.SET_INITIATIVE_FILTERS_AND_LENGTH]: (state, { payload }) => {
      return {
        ...state,
        initiativeFilters: payload.filters,
      }
    },

    [constants.SET_ALL_INITIATIVE_METADATA]: (state, { payload }) => {
      return {
        ...state,
        hasAttachments: payload.hasAttachments,
        initiativeListLength: payload.initiativesCount,
      }
    },

    [constants.CREATE_INITIATIVE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        initiativeList: [...state.initiativeList, payload],
      }
    },

    [constants.CREATE_MIRROR_INITIATIVE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        initiativeList: [...state.initiativeList, payload].sort((a, b) => a.listId - b.listId),
      }
    },

    [constants.CREATE_INITIATIVE_SUCCESS_FOR_INFINITY_LIST]: (state, { payload }) => {
      return {
        ...state,
        initiativeList: [{ ...payload, added: true }, ...state.initiativeList],
      }
    },

    [constants.UPDATE_SEVERAL_INITIATIVES]: (state, { payload }) => {
      const { previousInitiatives, newInitiatives, isMirroringInitiativesInactive } = payload

      const newInitiativeList = getListAfterUpdate({
        previousInitiatives,
        newInitiatives,
        initiativeList: state.initiativeList,
        isMirroringInitiativesInactive,
        initiativeListLength: state.initiativeListLength,
        sorting: state.initiativeFetchParams.sorting,
      })

      return {
        ...state,
        initiativeList: newInitiativeList,
      }
    },

    [constants.UPDATE_INITIATIVE_SUCCESS]: (state, { payload }) => {
      const { data, id, withoutUpdateSelectedInitiativeId } = payload
      let initiativeData = data

      if (state.isAllInitiative) {
        const initiativeBeforeUpdate = find({ id }, state.initiativeList)

        initiativeData = { ...data, added: initiativeBeforeUpdate.added }
      }

      let newInitiativeList = updateMirroredInitiatives({
        id,
        selectedInitiative: initiativeData,
        initiativeList: state.initiativeList,
      })

      if (state.isAllInitiative) {
        newInitiativeList = sortAllInitiativeList(newInitiativeList, state.initiativeFetchParams.sorting)
      } else {
        newInitiativeList = sortBy(['listId'], newInitiativeList)
      }

      return {
        ...state,
        initiativeList: newInitiativeList,
        ...(withoutUpdateSelectedInitiativeId ? {} : { selectedInitiativeId: data.id }),
      }
    },

    [constants.SET_IS_ALL_INITIATIVE]: (state, { payload }) => ({ ...state, isAllInitiative: payload }),

    [constants.DELETE_INITIATIVE_SUCCESS]: (state, { payload }) => {
      const { id } = payload
      const { initiativeList } = state
      const mirroredInitiativeIds = flow(
        find({ id }),
        getOr([], 'mirroredInitiatives'),
        map(({ initiativeId }) => initiativeId),
      )(initiativeList)

      const filteredInitiatives = initiativeList.filter(
        (initiative) => initiative.id !== id && !mirroredInitiativeIds.includes(initiative.id),
      )

      return {
        ...state,
        initiativeList: filteredInitiatives,
        selectedInitiativeId: null,
      }
    },

    [`${combineActions(constants.FETCH_FINANCIALS, constants.UPDATE_FINANCIALS_PENDING)}`]: (state) => {
      return assoc('financialsDataLoading', true, state)
    },

    [constants.SET_FINANCIALS]: (state, { payload: { financialsData } }) => ({
      ...state,
      financialsData,
      financialsDataLoading: false,
    }),

    [constants.UPDATE_FINANCIALS_SUCCESS]: (state, { payload: { financialItems } }) => {
      const { financialsData } = state

      if (isEmpty(financialsData)) {
        return merge(state, { financialsData: [financialItems], financialsDataLoading: false })
      }

      const updatedData = updateItems({ data: financialsData, items: financialItems, key: 'year' })

      return merge(state, { financialsData: updatedData, financialsDataLoading: false })
    },

    [`${combineActions(constants.UPDATE_FINANCIALS_FAIL, constants.SET_FINANCIALS_FAIL)}`]: (state) => {
      return assoc('financialsDataLoading', false, state)
    },

    [constants.UPDATE_STAGE_GATE_SUCCESS]: (state, { payload }) => {
      const { selectedInitiative, actualStageGateData, stageGateId } = payload
      const { mirroredInitiatives } = selectedInitiative

      const updatedInitiativeList = state.initiativeList.map((initiative) => {
        if (initiative.id === selectedInitiative.id) {
          const newInitiative = {
            ...initiative,
            stageGate: updateInitiativeStageGate({ initiative: selectedInitiative, stageGateId, actualStageGateData }),
          }

          const isL2ApprovedOld = checkIsStageGateReady(initiative, STAGE_GATE_IDS.L2_APPROVED)

          const isL2Approved = checkIsStageGateReady(newInitiative, STAGE_GATE_IDS.L2_APPROVED)

          if (isL2ApprovedOld !== isL2Approved) {
            newInitiative.stageGateAboveL2ApprovedUpdatedAt = isL2Approved ? formatNewDate() : null
          }

          return newInitiative
        }

        const mirroredState = mirroredInitiatives?.find((item: $TSFixMe) => item.initiativeId === initiative.id) || {}
        if (mirroredState.initiativeId === initiative.id) {
          return updateMirroredInitiative(selectedInitiative, initiative, {
            stageGate: updateInitiativeStageGate({ initiative: selectedInitiative, stageGateId, actualStageGateData }),
          })
        }

        return initiative
      })

      return {
        ...state,
        initiativeList: updatedInitiativeList,
      }
    },

    [constants.SET_STAGE_GATE_VALIDATION_STATUS]: (state, action) => {
      const { stageGateValidationStatus } = action.payload

      return assoc('stageGateValidationStatus', stageGateValidationStatus, state)
    },

    [constants.SET_INITIATIVE_COMMENTS_LIST]: (state, action) => {
      const { initiativeCommentsList } = action.payload

      return assoc('initiativeCommentsList', initiativeCommentsList, state)
    },

    [constants.CREATE_INITIATIVE_COMMENT_SUCCESS]: (state, action) => {
      const { data } = action.payload
      const updatedComments = [data, ...state.initiativeCommentsList]

      return assoc('initiativeCommentsList', updatedComments, state)
    },
    [constants.UPLOAD_INITIATIVE_ATTACHMENT_SUCCESS]: (state, { payload }) => {
      const { selectedInitiativeId, initiativeList } = state
      const updatedList = initiativeList.map((item) => {
        if (item.id === selectedInitiativeId) {
          return {
            ...item,
            attachments: [...item.attachments, payload.attachment],
          }
        }

        return item
      })

      const selectedInitiative = find({ id: selectedInitiativeId }, updatedList)

      return {
        ...state,
        hasAttachments: true,
        initiativeList: updateMirroredInitiatives({
          id: selectedInitiativeId,
          selectedInitiative,
          initiativeList: updatedList,
        }),
      }
    },

    [constants.DELETE_INITIATIVE_ATTACHMENT_SUCCESS]: (state, { payload }) => {
      const { selectedInitiativeId, initiativeList } = state

      const newInitiativeList = initiativeList.map((item) => {
        if (selectedInitiativeId === item.id) {
          return {
            ...item,
            attachments: item.attachments.filter((file: $TSFixMe) => file.id !== payload.fileId),
          }
        }

        return item
      })

      const selectedInitiative = find({ id: selectedInitiativeId }, newInitiativeList)

      return {
        ...state,
        initiativeList: updateMirroredInitiatives({
          id: selectedInitiativeId,
          selectedInitiative,
          initiativeList: newInitiativeList,
        }),
      }
    },
    [constants.SET_INITIATIVES_CATEGORIES]: (state, { payload }) => {
      const { data } = payload

      return {
        ...state,
        initiativesCategories: data,
      }
    },
    [constants.SET_MIRROR_EDIT_LOADING_STATE]: (state, { payload }) => ({
      ...state,
      mirrorEditLoadingState: payload,
    }),
    [constants.SET_SUB_LINES_FOR_MIRRORING]: (state, { payload: subLinesMirroring }) => ({
      ...state,
      subLinesMirroring,
    }),
    [constants.SAVE_INITIATIVE_FETCH_PARAMS]: (state, { payload }) => ({
      ...state,
      initiativeFetchParams: { ...state.initiativeFetchParams, ...payload },
    }),
    [constants.RESET_ALL_INITIATIVE_FILTERS]: (state) => ({
      ...state,
      initiativeFetchParams: initialState.initiativeFetchParams,
      initiativeFilters: initialState.initiativeFilters,
    }),
  },
  initialState,
)
