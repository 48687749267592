import { put, call } from 'redux-saga/effects'
import { setUser, setPermissions, setRole, setRoles, setIsFirstTimeUserSuccess } from '@generic/actions/actions'
import { accessController } from '@common/accessController'
import { getGenericApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { fetchInitialData } from './fetchInitialData'

export const fetchUserGenerator = function* fetchUser(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getGenericApi()
  const user = yield call(api.request('getUser'))

  // TODO: move all data manipulation to selectors. Rethink this solution
  const { permissions, role, roles, teamsPermissions, teamsWithoutVC, exceptions, redirects } =
    accessController.getPermissions({ ...user, teams: user.teams || [], roles: user.roles || [] })

  yield put(setPermissions({ permissions, teamsPermissions, teamsWithoutVC, exceptions, redirects }))
  yield put(setRole({ role }))
  yield put(setRoles({ roles }))
  yield put(setUser({ user }))

  return user
}

export const fetchUser = createSaga(fetchUserGenerator, true)

export const updateUserInfo = createSaga(function* updateUserInfo({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const data = {
    firstTime: payload.isFirstTimeUser,
  }

  const api = yield getGenericApi()
  yield call(api.request('updateUserInfo', { body: data }))

  // If we were specifically aiming to set the firstTime field then we need to update the store
  // with the new value now that the API call has succeeded. The TermsAndConditionsContent
  // component, for example, watches for this flag being false before redirecting the user.
  if (typeof data.firstTime === 'boolean') {
    yield put(setIsFirstTimeUserSuccess({ isFirstTimeUser: payload.isFirstTimeUser }))
  }

  yield call(fetchInitialData, { withLoader: false })
},
true)
