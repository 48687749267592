import React, { useEffect } from 'react'
import { FocusStyleManager } from '@blueprintjs/core'
import { useFeatureFlags } from './views/hooks/useFeatureFlags'
import { useDispatch, useSelector } from 'react-redux'
import { getServerRespondingState } from './domain/generic/selectors'
import { Outlet, useLocation } from 'react-router-dom'
import { useIsAskMyIMOModuleEnabled } from './views/hooks/useIsAskMyIMOModuleEnabled'
import { setRouterLocation } from './domain/generic/actions/actions'
import AppWrapper from './views/shared/AppWrapper'
import { AskContainer } from './views/shared/AskContainer/AskContainer'
import { SupportContainer } from './views/shared/AskContainer/SupportContainer'
import { DocsContainer } from './views/shared/AskContainer/DocsContainer'
import Sider from '@shared/Sider/containers'
import ErrorBoundary from '@shared/ErrorBoundary'
import './common/translation/i18n'
import './common/translation/i18n-constant'

FocusStyleManager.onlyShowFocusOnTabs()

export const RerenderOnLocationChange = ({ children }: { children: React.ReactNode }) => {
  /** Top level routes should rerender when the location changes,
   * even if navigating to the same page as current.
   */
  const location = useLocation()
  return <React.Fragment key={location.key}>{children}</React.Fragment>
}

export const AppLayout = () => {
  const { isEnabled } = useFeatureFlags()
  const isAskMyImoModuleEnabled = useIsAskMyIMOModuleEnabled()
  const serverRespondingState = useSelector(getServerRespondingState)
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setRouterLocation(location))
  }, [location])

  // Define routes where Sider should not appear
  const noSiderRoutes = ['/config/terms-conditions', '/dashboard/terms-conditions']

  const showSider = !noSiderRoutes.includes(location.pathname)

  return (
    <ErrorBoundary serverRespondingState={serverRespondingState}>
      <AppWrapper className="app-wrapper">
        {showSider && <Sider />}
        {isAskMyImoModuleEnabled && <AskContainer />}
        {isAskMyImoModuleEnabled && isEnabled('ASK_MYIMO_SUPPORT_BOT') && <SupportContainer />}
        {isAskMyImoModuleEnabled && isEnabled('ASK_MYIMO_DOCS') && <DocsContainer />}
        <RerenderOnLocationChange>
          <Outlet />
        </RerenderOnLocationChange>
      </AppWrapper>
    </ErrorBoundary>
  )
}

/** Legacy; preserved only to facilitate current integration test calls to render(<App/>) */
export const App = () => <></>
