import * as Yup from 'yup'
import { floatRegex, specialCharsRegex } from '@helpers/regex'
import { decimalCount, maxLen, minLen } from '@helpers/utils'
import moment from 'moment'
import { ValidationError } from './customErrors'
import { compact, flatten, flow, isNumber, isString, lowerCase, toNumber, uniqWith, upperFirst, values } from 'lodash'
import { toLowercaseSafe } from '@shared/Grid/utils/statusCellUtils'
import { MAX_INPUT_DECIMAL_COUNT, SPECIAL_CHARS_MESSAGE } from '@helpers/constants'

export const isSameEmailsValidator = (rule, value, callback, email) => {
  try {
    if (value === email) {
      throw new Error('Emails cannot be equal')
    }
  } catch (err) {
    callback(err)

    return
  }
  callback()
}

export const validateTeamNameUniq = (data) => {
  const allTeams = flow([values, flatten])(data)

  const onlyUniq = uniqWith(
    allTeams,
    (curVal, otherVal) => curVal.longName === otherVal.longName || curVal.shortName === otherVal.shortName,
  )

  if (onlyUniq.length !== allTeams.length)
    throw new ValidationError({ message: "Teams' long and short names should be unique" })
}

export const lengthValidation = (max, min, customMessage) => (value) => {
  const isMaxLen = !max || maxLen(value, max)
  const isMinLen = !min || minLen(value, min)
  const isValid = isMaxLen && isMinLen

  const message =
    customMessage ||
    `Input should have ${max ? 'maximum ' + max + ' ' : ''}${!!min && !!max ? 'and ' : ''}${
      min ? 'minimum ' + min + ' ' : ''
    }symbols.`

  return {
    valid: isValid,
    message: isValid ? null : message,
  }
}

export const minMaxValidation = (max, min, message) => (value) => {
  const inputValue = isString(value) ? toNumber(value.replace(/[^\d.]/u, '')) : value
  const valid = floatRegex.test(inputValue) && isNumber(min) && inputValue >= min && inputValue <= max

  const msg = message ? message : `Input should be ${max} ${min ? 'and minimum ' + min : ''}.`

  return {
    valid,
    message: valid ? null : msg,
  }
}

export const valuesPresenceValidation =
  (keysToCheck = []) =>
  (value, obj) => {
    const isValid = keysToCheck.every((key) => !!obj[key])

    return {
      valid: isValid,
      message: isValid ? null : `${upperFirst(keysToCheck.map((k) => lowerCase(k)).join(', '))} should be filled.`,
    }
  }

export const valuesAbsenceValidation =
  (keysToCheck = []) =>
  (value, obj) => {
    const isValid = !!value || keysToCheck.every((key) => !obj[key])

    return {
      valid: isValid,
      message: isValid ? null : `${upperFirst(keysToCheck.map((k) => lowerCase(k)).join(', '))} should be empty.`,
    }
  }

export const symbolsValidation = () => (value) => {
  const isValid = !value ? true : !value.match(specialCharsRegex)

  return {
    valid: isValid,
    message: isValid ? null : SPECIAL_CHARS_MESSAGE,
  }
}

export const duplicatesValidation = (values, customMessage) => (value) => {
  const isValid = !values.includes(value)
  return {
    valid: isValid,
    message: isValid ? null : customMessage,
  }
}

export const emailValidation = () => (value) => {
  const isValid = Yup.string().max(60).email().isValidSync(value)

  return {
    valid: isValid,
    message: isValid ? null : 'Please enter a valid email address',
  }
}

export const combineValidators = (validators, ...params) => {
  const errors = validators.reduce((arr, validator) => {
    const isValid = validator(...params)
    if (!isValid.valid) {
      arr.push(isValid.message)
    }

    return arr
  }, [])

  return {
    valid: !errors.length,
    message: !errors.length ? null : errors.join(' '),
  }
}

export const duplicatedFieldValidation =
  (allProcesses, field = 'name', label = 'item', caseSensitive = true) =>
  (name) => {
    const isDuplicatedName = allProcesses.some((process) =>
      caseSensitive ? process[field] === name : toLowercaseSafe(process[field]) === toLowercaseSafe(name),
    )

    return {
      valid: !isDuplicatedName,
      message: !isDuplicatedName ? null : `${upperFirst(label)} with name '${name}' already exists.`,
    }
  }

export const validateAttachment = ({ name }, attachmentsList) => {
  const isDuplicatedName = attachmentsList.some(({ key }) => key.split('/').slice(-1).pop() === name)

  return {
    valid: !isDuplicatedName,
    message: !isDuplicatedName ? null : `File with name ${name} already attached`,
  }
}

export const timeValidation = () => (value) => {
  const date = moment(value, 'HH:mm:ss')
  const isValid = date.isValid()

  return {
    valid: isValid,
    message: isValid ? null : `Input should be valid GMT time.`,
  }
}

export const decimalCountValidation =
  (maxDecimalCount = MAX_INPUT_DECIMAL_COUNT) =>
  (value) => {
    const isValid = !value || decimalCount(value) <= maxDecimalCount

    return {
      valid: isValid,
      message: isValid ? null : `The number's count after decimal should less or equal ${maxDecimalCount}.`,
    }
  }

export function prepareDomainRestrictions(domains) {
  return domains.map((domain) => {
    const matchResult = domain.match(/^(\*)?(.*?)(\*)?$/u) || []
    const [, subdomain, parsedDomain, suffix] = matchResult
    return { subdomain: !!subdomain, suffix: !!suffix, domain: parsedDomain }
  })
}

export const validateDomains = ({ user, domains }) => {
  if (!user.email) {
    return false
  }
  const emailDomain = user.email.split('@')[1].toLowerCase()
  const parsedRestrictedDomains = prepareDomainRestrictions(domains)

  const isRestricted = parsedRestrictedDomains.some(({ domain, subdomain, suffix }) => {
    if (subdomain && suffix) {
      return emailDomain.includes(`.${domain}.`) || emailDomain.startsWith(`${domain}.`)
    }
    if (subdomain) {
      return emailDomain.includes(`.${domain}`) || emailDomain === domain
    }
    if (suffix) {
      return emailDomain.startsWith(`${domain}.`)
    }
    return emailDomain === domain
  })
  return isRestricted
}

export const listIdFormatValidation = (hierarchyLevel, itemType) => (value) => {
  const isValid = !value || compact(value.split('.')).length === hierarchyLevel

  const message = `Invalid ${itemType} ID`

  return {
    valid: isValid,
    message: isValid ? null : message,
  }
}
