import React from 'react'
import Spinner from '@shared/Spinner'
import { LoaderText, StyledLoader } from './index.styles'

interface LoaderProps {
  loaderText?: string
  showSpinner?: boolean
  position?: 'fixed' | 'absolute'
  size?: number
  testId?: string | null
}

const Loader: React.FC<LoaderProps> = ({
  loaderText,
  showSpinner = true,
  position = 'fixed',
  size = 100,
  testId = null,
}) => {
  return (
    <StyledLoader position={position} data-testid={testId}>
      {showSpinner && <Spinner size={size} />}
      {loaderText && <LoaderText>{loaderText}</LoaderText>}
    </StyledLoader>
  )
}

export default Loader
