import { get, groupBy, isFunction, mapValues, omit, pickBy } from 'lodash'
import {
  find,
  flow,
  get as getFp,
  groupBy as groupByFp,
  isEqual,
  pickBy as pickByFp,
  size,
  isEmpty,
  isNil,
  pick,
  matches,
  isString,
  toLower,
} from 'lodash/fp'
import { isL4PlannedDateNotMatchDueDate, formattedDateToLLFormat } from '@helpers/utils'
import { userRoles } from '@common/accessController/constants'

export const dueDateError = ({ dataDueDate }) => {
  const dateSubString = isNil(dataDueDate) ? '' : `<b>${formattedDateToLLFormat(dataDueDate)}</b> `

  return `The <b>Due date</b> for the project does not match the ${dateSubString}L4 date (plan) of the linked synergy initiative`
}

export function hasProjectsProcess(value = {}) {
  const { path } = this
  const options = [...this.parent]

  if (value.type === 'keyProcess' && !!value.required) {
    const children = options.filter((item) => item.type === 'project' && value.id === item.keyProcessId)

    if (children.length < 1) {
      throw this.createError({ path: `${path}.name` })
    }
  }

  return true
}

export const validateDueDate = ({ value, data }) => {
  const linkedInitiative = data.linkedInitiative

  if (isNil(linkedInitiative)) return true

  if (isNil(value) && isNil(linkedInitiative.plannedL4Date)) return true

  return !isL4PlannedDateNotMatchDueDate(value, linkedInitiative.plannedL4Date)
}

export function hasTasksProcess(value) {
  const { path } = this
  const options = [...this.parent]
  const isRequiredParent = !!flow(find({ id: value.keyProcessId, type: 'keyProcess' }), getFp('required'))(options)

  if (value.type === 'project' && isRequiredParent) {
    const children = options.filter((item) => item.type === 'task' && value.id === item.projectId)

    if (children.length < 2) {
      throw this.createError({ path: `${path}.name` })
    }
  }

  return true
}

export const getValueToCompare = (value, fields, caseSensitive = true) => {
  const newValue = pickBy(value, (v, k) => fields.includes(k) && !!v)

  return caseSensitive ? newValue : mapValues(newValue, (v) => (isFunction(v.toLowerCase) ? v.toLowerCase() : v))
}

export const combinePaths = (basePath, paths = []) => {
  return paths.map((path) => `${basePath}.${path}`).join(',')
}

const pickUserFullName = pick(['name', 'surname'])
export const checkUserDuplications = (currentUser, users) => {
  const loweredEmail = currentUser.email.toLowerCase()
  let result = []

  for (let i = 0; i < users.length; i++) {
    if (users[i].id === currentUser.id) continue

    const user = users[i]
    const areEmailsEqual = user.email && currentUser.email && user.email.toLowerCase() === loweredEmail

    if (areEmailsEqual) result.push('email')

    if (matches(pickUserFullName(currentUser), pickUserFullName(user))) result.push('name', 'surname')

    if (size(result)) break
  }

  return result
}

const getDuplicatedFields = (row1, row2, fields) => {
  return fields.reduce((acc, field) => {
    let value1 = getFp(field, row1)
    let value2 = getFp(field, row2)

    if (isString(value1)) {
      value1 = toLower(value1)
      value2 = toLower(value2)
    }

    if (isEqual(value1, value2)) {
      acc.push(field)
    }

    return acc
  }, [])
}

export const checkRowsDuplications = (currentRow, rows, fieldsCombinationToCheck = []) => {
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].id === currentRow.id) continue

    const result = getDuplicatedFields(currentRow, rows[i], fieldsCombinationToCheck)

    if (result.length === fieldsCombinationToCheck.length) return result
  }

  return []
}

export const isUnique = (fields, caseSensitive) =>
  function isUnique(value) {
    const duplicates = flow(
      groupByFp((val) => JSON.stringify(getValueToCompare(val, fields, caseSensitive))),
      pickByFp((x) => x.length > 1),
    )(value)

    if (Object.keys(duplicates).length) {
      const message = Object.values(duplicates)
        .map(([v]) => `${Object.values(getValueToCompare(v, fields)).join(', ')} already exists`)
        .join(' ')

      throw this.createError({
        message: message,
      })
    }

    return true
  }

export function isPresentFunctions(value) {
  const functions = groupBy(value, 'type')
  const isAllFunctionsPresent = ['Corporate', 'Commercial', 'Operational'].every((i) => {
    return get(functions, [i, 'length'], 0) > 0
  })

  if (!isAllFunctionsPresent) {
    throw this.createError({ path: 'functions' })
  }

  return true
}

export const combineErrorsByPath = ({ value, inner }) => ({
  value,
  ...groupBy(inner, ({ path }) => get(path.split(/(\.|\[)/gu), 0)),
})

export const validateFormSchema = (schema, setErrors) => (data, id) => {
  try {
    if (id) {
      schema.validateSyncAt(id, { [id]: data })
      setErrors((errors) => omit(errors, [id]))
    } else {
      schema.validateSync(data, { abortEarly: false })
      setErrors({})
    }

    return true
  } catch (e) {
    const newErrorState = id ? { [id]: e.error } : combineErrorsByPath(e)
    setErrors((errorState) => ({ ...errorState, ...newErrorState }))

    return false
  }
}

export function hasOnlyRestrictedRole(value) {
  const { path } = this
  const { isRestricted, activeRestrictions, roles } = { ...this.parent }
  const { allTeams } = value

  // case if user has restricted domain but restricted domains are allowed (in launchpad)
  if (isRestricted && !activeRestrictions) {
    if (isEmpty(value.activeTeams))
      return this.createError({
        message: 'Only the Restricted User role is allowed for users under this email domain',
        path,
      })

    return true
  }

  if (isRestricted) {
    if (isEmpty(value.activeTeams)) return this.createError({ path })

    // user with restricted domain can't have admin role
    if (roles.some((role) => role.active)) return this.createError({ path })

    const hasOnlyRestricted = Object.values(allTeams).every((teamRoles) => {
      const activeRoles = Object.keys(teamRoles).filter((key) => teamRoles[key])

      const normalizedRoles = activeRoles.map((role) => role.toLowerCase())
      const targetRoles = [userRoles.RESTRICTED, userRoles.RESTRICTED_WITH_VC].map((role) => role.toLowerCase())
      const hasOnlyRestrictedRoles = normalizedRoles.every((role) => targetRoles.includes(role))

      return !size(activeRoles) || hasOnlyRestrictedRoles
    })

    if (!hasOnlyRestricted) return this.createError({ path })
  }

  return true
}

export function validateCostImpact(value) {
  const { path } = this

  if (value < 0) return this.createError({ path, params: { isWarning: true } })

  return true
}

export function validateOneTimeCostLinking() {
  const { path, parent } = this

  if (!parent.initiative && !parent.linkedProject) return this.createError({ path })

  return true
}
