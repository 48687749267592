import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import LoaderWithBackDrop from '@shared/LoaderWithBackDrop'

const withLoader = (Component) => {
  const loaderWrapped = (props) => {
    const { loader } = props

    return (
      <>
        <LoaderWithBackDrop isShown={loader} showSpinner={false} />
        <Component {...props} />
      </>
    )
  }

  loaderWrapped.propTypes = {
    loader: PropTypes.bool.isRequired,
  }

  const mapStateToProps = (state) => ({
    loader: state.generic.loader,
  })

  return compose(connect(mapStateToProps), memo)(loaderWrapped)
}

export default withLoader
