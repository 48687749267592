import { omit, pick } from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getDayOneApi } from '@common/net'
import { transformEmptyStringsToNull } from '@helpers/utils'

import { createSaga } from '@common/sagaCreator/createSaga'
import { parseOwner } from '@shared/Grid/utils/ownerCellUtils'
import * as constants from '@dayOne/actions/actionTypes'
import { delay } from 'redux-saga'
import { parseOwners } from '@shared/Grid/CellEditors/utils'
import { isEmpty } from 'lodash/fp'
import { getRowNodeIdProcessItem } from '@shared/DayOne/utils/mapDayOneData'
import { setSelectedProcessId } from '@dayOne/actions/actions'
import { refreshPageDialogParams } from '../../utils'
import { openNotificationWithIcon } from '@imo/imo-ui-toolkit'

export const createProject = createSaga(function* createProject(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data: projectData, onSuccess, onFailure, disableLoadingOverlay } = action.payload
  try {
    const requestData = pick(projectData, ['name', 'confidential'])

    const dayOneApi = yield getDayOneApi()
    const createdData = yield call(
      dayOneApi.request('createProject', {
        query: {
          teamId: teamId,
          keyProcessId: projectData.keyProcessId,
          projectId: projectData.id,
        },
        body: requestData,
      }),
    )

    yield disableLoadingOverlay()

    const rowNodeId = getRowNodeIdProcessItem({ ...projectData, ...createdData, teamId, rowId: undefined })

    yield put(setSelectedProcessId(rowNodeId))

    if (onSuccess) yield call(onSuccess)
  } catch (e) {
    yield call(delay, 100)
    if (onFailure) yield call(onFailure)

    throw e
  }
})

export const updateProject = createSaga(function* updateProject(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const {
    teamId,
    data: projectData,
    onSuccess,
    onFailure,
    optimisticUpdate = true,
    isDueDataChanged = false,
  } = action.payload

  try {
    if (onSuccess && optimisticUpdate) yield call(onSuccess)

    const dayOneApi = yield getDayOneApi()
    const responseData = yield call(
      dayOneApi.request('updateProject', {
        query: {
          teamId: teamId,
          keyProcessId: projectData.keyProcessId,
          projectId: projectData.id,
        },
        body: transformEmptyStringsToNull({
          ...omit(projectData, [
            'hierarchy',
            'timeFlag',
            'type',
            'id',
            'index',
            'attachments',
            'isIncoming',
            'longTeamName',
            'primaryIMO',
            'teamId',
            'ancestor',
            'linkedInitiative',
            'linkedOneTimeCost',
            'customColumns',
            'libraryFunctionId',
            'tasks',
            'mirroringTeams',
          ]),
          owner: parseOwner(projectData.owner),
          teamMembers: isEmpty(projectData.teamMembers) ? null : parseOwners(projectData.teamMembers),
        }),
      }),
    )

    if (onSuccess && !optimisticUpdate) yield call(onSuccess)

    if (onSuccess && isDueDataChanged) {
      const successPayload = {
        forecastDate: responseData.forecastDate,
        isDueDataChanged,
      }

      yield call(onSuccess, { payload: successPayload })
    }
  } catch (e) {
    yield call(delay, 100)

    const { status } = e as $TSFixMe
    if (status === 404) {
      openNotificationWithIcon(refreshPageDialogParams)

      return
    }

    if (onFailure) yield call(onFailure)

    throw e
  }
},
true)

export const deleteProject = createSaga(function* deleteProject(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data: projectData, onSuccess } = action.payload
  const dayOneApi = yield getDayOneApi()
  try {
    yield call(
      dayOneApi.request('deleteProject', {
        query: {
          teamId: teamId,
          keyProcessId: projectData.keyProcessId,
          projectId: projectData.id,
        },
      }),
    )

    if (onSuccess) yield call(onSuccess)
  } catch (e) {
    yield call(delay, 100)

    const { status } = e as $TSFixMe
    if (status === 404) {
      openNotificationWithIcon(refreshPageDialogParams)

      return
    }

    throw e
  }
})

export default [
  takeLatest(constants.CREATE_PROJECT, createProject),
  takeLatest(constants.UPDATE_PROJECT, updateProject),
  takeLatest(constants.DELETE_PROJECT, deleteProject),
]
