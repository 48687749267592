import React from 'react'
import Backdrop from '../BackDrop'
import Loader from '../Loader'

interface LoaderWithBackDropProps {
  isShown?: boolean
  loaderText?: string
  position?: 'fixed' | 'absolute'
  showSpinner?: boolean
  size?: number
}

const LoaderWithBackDrop: React.FC<LoaderWithBackDropProps> = ({
  isShown,
  position,
  loaderText,
  showSpinner = true,
  size = 150,
}) => (
  <Backdrop isShown={isShown} position={position}>
    <Loader showSpinner={showSpinner} loaderText={loaderText} size={size} />
  </Backdrop>
)

export default LoaderWithBackDrop
