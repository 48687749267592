import { get, pick } from 'lodash'
import nanoid from 'nanoid'
import { GridApi } from 'ag-grid-community'
import { listItemTypes } from '@shared/DayOne/constants'
import { isKeyProcess, isProject } from '@shared/DayOne/utils/getProcessStatus'
import { getRowNodeIdProcessItem } from '../../DayOne/utils/mapDayOneData'

interface BaseNewItem {
  name: string
  isNew: boolean
  custom: boolean
  id: string
  projectListId: string
  predecessorId: null
  rowId?: string
  hierarchy?: string[]
  teamId: number
  longTeamName: string
  primaryIMO: string
  confidential?: boolean | null
}

interface NewKeyProcessItem extends BaseNewItem {
  required: boolean
}

interface NewProjectItem extends BaseNewItem {
  keyProcessId: number
}

interface NewTaskItem extends BaseNewItem {
  projectId: number
}

const newItemDefaultData = {
  name: '',
  isNew: true,
  custom: true,
  id: nanoid(),
  projectListId: '',
  predecessorId: null,
}

const newChildItemData = {
  ...newItemDefaultData,
  status: 'not started',
  priority: null,
  confidential: null,
  dueDate: null,
  forecastedDate: null,
}

export const addKeyProcess = (selectedItem: $TSFixMe): NewKeyProcessItem => {
  const baseItemData = {
    ...newItemDefaultData,
    required: false,
    type: listItemTypes.KEY_PROCESS,
    ...pick(selectedItem, ['teamId', 'longTeamName', 'primaryIMO']),
  }

  if (selectedItem.rowId) {
    return {
      ...baseItemData,
      rowId: newItemDefaultData.id,
      hierarchy: [newItemDefaultData.id],
    }
  }

  return baseItemData
}

export const addProject = (selectedItem: $TSFixMe, api: GridApi, isConfidential: boolean): NewProjectItem => {
  const isKeyProcessItem = isKeyProcess(selectedItem)
  const keyProcessId = isKeyProcessItem ? get(selectedItem, 'id') : get(selectedItem, 'keyProcessId')
  if (isKeyProcessItem) {
    const rowNode = api.getRowNode(getRowNodeIdProcessItem(selectedItem))
    if (rowNode) {
      api.setRowNodeExpanded(rowNode, true)
    }
  }

  const baseItemData = {
    ...newChildItemData,
    keyProcessId,
    type: listItemTypes.PROJECT,
    ...pick(selectedItem, ['teamId', 'longTeamName', 'primaryIMO']),
    ...(isConfidential ? { confidential: true } : {}),
  }

  if (selectedItem.rowId) {
    const [keyProcessRowId] = get(selectedItem, 'hierarchy')
    return {
      ...baseItemData,
      rowId: newItemDefaultData.id,
      hierarchy: [keyProcessRowId, newItemDefaultData.id],
    }
  }

  return baseItemData
}

export const addTask = (selectedItem: $TSFixMe, api: GridApi, isConfidential: boolean): NewTaskItem => {
  const isProjectItem = isProject(selectedItem)
  const projectId = isProjectItem ? get(selectedItem, 'id') : get(selectedItem, 'projectId')
  if (isProjectItem) {
    const rowNode = api.getRowNode(getRowNodeIdProcessItem(selectedItem))
    if (rowNode) {
      api.setRowNodeExpanded(rowNode, true)
    }
  }

  const baseItemData = {
    ...newChildItemData,
    projectId,
    keyProcessId: get(selectedItem, 'keyProcessId'),
    type: listItemTypes.TASK,
    hierarchy: undefined,
    ...pick(selectedItem, ['teamId', 'longTeamName', 'primaryIMO']),
    ...(isConfidential ? { confidential: true } : {}),
  }

  if (selectedItem.rowId) {
    const [keyProcessRowId, projectRowId] = get(selectedItem, 'hierarchy')
    return {
      ...baseItemData,
      rowId: newItemDefaultData.id,
      hierarchy: [keyProcessRowId, projectRowId, newItemDefaultData.id],
    }
  }

  return baseItemData
}

export const getProjectPlanNewRow = (
  itemType: $TSFixMe,
  selectedItem: $TSFixMe,
  api: GridApi,
  isConfidential: boolean,
) => {
  switch (itemType) {
    case listItemTypes.KEY_PROCESS:
      return addKeyProcess(selectedItem)
    case listItemTypes.PROJECT:
      return addProject(selectedItem, api, isConfidential)
    case listItemTypes.TASK:
      return addTask(selectedItem, api, isConfidential)
    default:
      return null
  }
}
