import styled from 'styled-components'

export const LoaderBackdrop = styled.div`
  top: 0;
  left: 0;
  background-color: rgba(173, 173, 173, 0.5);
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
