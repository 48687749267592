import { listItemTypes } from '@shared/DayOne/constants'
import { GANTT_PERMISSION, PERMISSION_ACTION } from '@common/accessController/constants'
import { itemFields } from '@common/accessController/strategies/constants'

export const processItemFields = {
  NAME: 'name',
  PROJECT_LIST_ID: 'projectListId',
  STATUS: 'status',
  PRIORITY: 'priority',
  DUE_DATE: 'dueDate',
  FORECAST_DATE: 'forecastDate',
  OWNER: 'owner',
  OWNERS: 'owners',
  CONFIDENTIAL: 'confidential',
  OTHER_TEAM_MEMBERS: 'teamMembers',
  INITIATIVE: 'initiative',
  ONE_TIME_COST: 'oneTimeCost',
  DESCRIPTION: 'description',
  START_DATE: 'startDate',
  COMMENTS: 'comments',
  ANCESTOR: 'ancestor',
  PREDECESSOR: 'predecessor',
  FOLLOWER: 'follower',
  ATTACHMENTS: 'attachments',
  INTERDEPENDENCY: 'interdependency',
  CATEGORY: 'projectCategoryId',

  BATCH_UPLOAD: 'batch upload',
  UNIQUE_ID: 'uniqueIdentifier',
}

export const teamDeliverablePermissions = [
  PERMISSION_ACTION.SUBMIT,
  PERMISSION_ACTION.REVIEW,
  itemFields.VIEW_COMMENTS,
  itemFields.ADD_COMMENTS,
  itemFields.LIBRARY,
]

export const defaultItemsPermission = [PERMISSION_ACTION.EDIT, processItemFields.BATCH_UPLOAD, GANTT_PERMISSION.VIEW]

export const processFields = [processItemFields.NAME, processItemFields.PROJECT_LIST_ID, processItemFields.CONFIDENTIAL]

export const projectFields = [
  ...processFields,
  processItemFields.OWNER,
  processItemFields.STATUS,
  processItemFields.PRIORITY,
  processItemFields.DUE_DATE,
  processItemFields.FORECAST_DATE,
  processItemFields.CONFIDENTIAL,
  processItemFields.DESCRIPTION,
  processItemFields.START_DATE,
  processItemFields.COMMENTS,
  processItemFields.OTHER_TEAM_MEMBERS,
  processItemFields.CATEGORY,
  itemFields.CUSTOM,

  processItemFields.PREDECESSOR,
  processItemFields.FOLLOWER,
  processItemFields.INITIATIVE,
  processItemFields.ONE_TIME_COST,

  processItemFields.ATTACHMENTS,
]

export const taskFields = [...projectFields, processItemFields.INTERDEPENDENCY]

export const itemTypesFieldsMap = {
  [listItemTypes.KEY_PROCESS]: processFields,
  [listItemTypes.PROJECT]: projectFields,
  [listItemTypes.TASK]: taskFields,
}

export const interdependencyDisallowFields = {
  [listItemTypes.KEY_PROCESS]: [processItemFields.NAME, processItemFields.PROJECT_LIST_ID],
  [listItemTypes.PROJECT]: [
    processItemFields.NAME,
    processItemFields.PREDECESSOR,
    processItemFields.FOLLOWER,
    processItemFields.INITIATIVE,
    processItemFields.ONE_TIME_COST,
    processItemFields.PROJECT_LIST_ID,
  ],
  [listItemTypes.TASK]: [processItemFields.PREDECESSOR, processItemFields.FOLLOWER, processItemFields.PROJECT_LIST_ID],
}

export const mirrorDisallowFields = {
  [listItemTypes.KEY_PROCESS]: [processItemFields.NAME, processItemFields.PROJECT_LIST_ID],
  [listItemTypes.PROJECT]: [...Object.values(projectFields)],
  [listItemTypes.TASK]: [...Object.values(taskFields)],
}

export const RESTRICTED_NOT_OWNER_EXCEPTION_FIELDS = [processItemFields.PROJECT_LIST_ID]

export const tsaDeliverablesPermissions = [
  PERMISSION_ACTION.DELETE,
  PERMISSION_ACTION.ADD,
  PERMISSION_ACTION.EDIT,
  PERMISSION_ACTION.REVIEW,
  itemFields.LOCKED,
  itemFields.IS_ALL_LOCKED,
  itemFields.IS_PHASE_1_LOCKED,
  itemFields.IS_PHASE_2_LOCKED,
  itemFields.IS_PHASE_3_LOCKED,
  itemFields.IS_PHASE_4_LOCKED,
  itemFields.IS_PHASE_5_LOCKED,
]
