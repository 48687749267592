import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Intent, Checkbox, Button } from '@blueprintjs/core'
import {
  StyledWelcomeContainer,
  StyledLogoContainer,
  StyledWelcomeText,
  StyledHelloText,
  StyledMyIMOText,
  StyledAreaContainer,
  StyledAreaHeader,
  StyledActionsContainer,
  StyledTextarea,
  StyledPopup,
  StyledMyIMOButton,
  StyledLogoHeaderContainer,
  StyledBodyContainer,
  CommonStyledButton,
} from './TermsAndConditions.styles'
import myimoLogo from '@assets/svg/myimo-logo.svg'
import { useTranslation } from 'react-i18next'

import { setIsFirstTimeUser } from '@generic/actions/actions'
import { getManagementType } from '@domain/instanceConfig/selectors'
import { getConfig, getIsFirstTimeUser, userInfo as getUserInfo } from '@generic/selectors'
import { isSMO } from '@myImoConfig/containers/ConfigSteps/utils'
import { useNavigate } from 'react-router-dom'

import Animation from '@shared/Animation'
import Paginator from '@views/myImoClient/components/TermsAndConditions/Paginator/Paginator'

interface Team {
  id: string
  longName: string
  role: string
}

const WELCOME_STEP = 0
const DISCLAIMER_STEP = 1
const TERM_CONDITION_STEP = 2
const DONE_STEP = 3

const ANIMATION_TIMEOUT = 300

const TermsAndConditionsContent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const [acceptEnabled, setAcceptEnabled] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [isAnimationVisible, setAnimationVisible] = useState(false)

  const { legalDisclaimer, termsConditions } = useSelector(getConfig)
  const managementType = useSelector(getManagementType)
  const userInfo = useSelector(getUserInfo)
  const isFirstTimeUser = useSelector(getIsFirstTimeUser)

  const welcomeMessage = t('WELCOME_DISCLAMER_SCREEN.WELCOME_PAGE.WELCOME_MESSAGE')
  const helloMessage = t('WELCOME_DISCLAMER_SCREEN.WELCOME_PAGE.HELLO_MESSAGE', { managementType })
  const helloName = t('WELCOME_DISCLAMER_SCREEN.WELCOME_PAGE.HELLO_NAME', { name: userInfo.name })
  const explanationProjectMessage = t('WELCOME_DISCLAMER_SCREEN.WELCOME_PAGE.PROJECT_EXPLANATION_MESSAGE', {
    managementType,
    integrationType: isSMO(managementType)
      ? t('WELCOME_DISCLAMER_SCREEN.WELCOME_PAGE.SEPARATION')
      : t('WELCOME_DISCLAMER_SCREEN.WELCOME_PAGE.INTEGRATION'),
  })

  useEffect(() => {
    if (currentStep === WELCOME_STEP) {
      setAnimationVisible(true)
    }
  }, [])

  useEffect(() => {
    if (!isFirstTimeUser && currentStep === DONE_STEP) {
      navigate('/')
    }
  }, [currentStep, isFirstTimeUser])

  const handleStepTransition = (step: number) => {
    if (
      (currentStep === WELCOME_STEP && step === DISCLAIMER_STEP) ||
      (currentStep === DISCLAIMER_STEP && step === WELCOME_STEP)
    ) {
      setAnimationVisible(false)
      setTimeout(() => {
        setCurrentStep(step)
        setAnimationVisible(true)
      }, ANIMATION_TIMEOUT)
    } else {
      setCurrentStep(step)
    }
  }

  const handleAccept = (step: number) => {
    if (step === DONE_STEP) {
      dispatch(setIsFirstTimeUser({ isFirstTimeUser: false }))
    }

    handleStepTransition(step)
  }

  const handleCheckboxClick = () => {
    setAcceptEnabled(!acceptEnabled)

    setTimeout(() => {
      const acceptButton = document.getElementById('accept-button')
      if (acceptButton) {
        acceptButton.focus()
      }
    })
  }

  return (
    <>
      {currentStep === WELCOME_STEP && (
        <Animation isVisible={isAnimationVisible} direction="in" timeout={ANIMATION_TIMEOUT} animationType="fade">
          <StyledWelcomeContainer>
            <StyledLogoContainer>
              <img src={myimoLogo} alt={`${managementType} Logo`} width="100" />
            </StyledLogoContainer>
            <StyledWelcomeText>
              {welcomeMessage}
              {Array.isArray(userInfo.teams) &&
                userInfo.teams.map((team: Team, index: number) => (
                  <span key={`team-role-${team.id}`}>
                    {team.longName}
                    {index < userInfo.teams.length - 1 ? ', ' : '.'}
                  </span>
                ))}
            </StyledWelcomeText>
            <StyledHelloText>
              {helloName},<br />
              {helloMessage}
            </StyledHelloText>
            <StyledMyIMOText>
              <p>{explanationProjectMessage}</p>
            </StyledMyIMOText>
            <StyledMyIMOButton>
              <Button
                id="accept-button"
                aria-label={'Welcome screen - Continue'}
                onClick={() => {
                  handleAccept(1)
                }}
              >
                {t('WELCOME_DISCLAMER_SCREEN.WELCOME_PAGE.CONTINUE_ACTION')}
              </Button>
            </StyledMyIMOButton>
          </StyledWelcomeContainer>
        </Animation>
      )}

      {currentStep === DISCLAIMER_STEP && (
        <Animation isVisible={isAnimationVisible} direction="in" timeout={ANIMATION_TIMEOUT} animationType="fade">
          <div>
            <StyledPopup
              disableHeader={true}
              className="legal-disclaimer-popup"
              overlayClassName="terms-condition-overlay"
            >
              <StyledLogoHeaderContainer />
              <StyledBodyContainer>
                <StyledAreaContainer>
                  <StyledAreaHeader id="legal-disclaimer-header">
                    {t('WELCOME_DISCLAMER_SCREEN.DISCLAIMER_PAGE.TITLE')}
                  </StyledAreaHeader>
                  <StyledTextarea defaultValue={legalDisclaimer} aria-describedby="legal-disclaimer-header" disabled />
                </StyledAreaContainer>
                <StyledActionsContainer className="pagination-action-block">
                  <Paginator
                    onClick={handleAccept}
                    pages={[
                      { active: true, link: 1 },
                      { active: false, link: 2 },
                    ]}
                  >
                    <CommonStyledButton
                      id="back-button"
                      style={{ alignSelf: 'flex-start' }}
                      onClick={() => handleAccept(0)}
                    >
                      {t('WELCOME_DISCLAMER_SCREEN.WELCOME_PAGE.BACK_ACTION')}
                    </CommonStyledButton>
                    <CommonStyledButton
                      id="continue-button"
                      style={{ alignSelf: 'flex-end' }}
                      intent={Intent.PRIMARY}
                      onClick={() => handleAccept(TERM_CONDITION_STEP)}
                    >
                      {t('WELCOME_DISCLAMER_SCREEN.WELCOME_PAGE.NEXT_ACTION')}
                    </CommonStyledButton>
                  </Paginator>
                </StyledActionsContainer>
              </StyledBodyContainer>
            </StyledPopup>
          </div>
        </Animation>
      )}
      {currentStep === TERM_CONDITION_STEP && (
        <div>
          <StyledPopup
            disableHeader={true}
            className="terms-condition-popup"
            overlayClassName="terms-condition-overlay"
          >
            <StyledLogoHeaderContainer />
            <StyledBodyContainer>
              <StyledAreaContainer>
                <StyledAreaHeader id="term-condition-header">
                  {t('WELCOME_DISCLAMER_SCREEN.TERM_CONDITION_PAGE.TITLE')}
                </StyledAreaHeader>
                <StyledTextarea defaultValue={termsConditions} aria-describedby="term-condition-header" disabled />
              </StyledAreaContainer>
              <StyledActionsContainer className="accept-block">
                <Checkbox
                  className="checkbox"
                  checked={acceptEnabled}
                  label="I have read and agree to the Terms and Conditions"
                  onChange={handleCheckboxClick}
                />
              </StyledActionsContainer>
              <StyledActionsContainer className="pagination-action-block">
                <Paginator
                  onClick={handleAccept}
                  pages={[
                    { active: false, link: 1 },
                    { active: true, link: 2 },
                  ]}
                >
                  <CommonStyledButton
                    id="back-button"
                    style={{ alignSelf: 'flex-start' }}
                    onClick={() => handleAccept(DISCLAIMER_STEP)}
                  >
                    {t('WELCOME_DISCLAMER_SCREEN.WELCOME_PAGE.BACK_ACTION')}
                  </CommonStyledButton>
                  <CommonStyledButton
                    id="accept-button"
                    style={{ alignSelf: 'flex-end' }}
                    disabled={!acceptEnabled}
                    intent={Intent.PRIMARY}
                    onClick={() => handleAccept(DONE_STEP)}
                  >
                    {t('WELCOME_DISCLAMER_SCREEN.WELCOME_PAGE.FINISH_ACTION')}
                  </CommonStyledButton>
                </Paginator>
              </StyledActionsContainer>
            </StyledBodyContainer>
          </StyledPopup>
        </div>
      )}
    </>
  )
}

export default TermsAndConditionsContent
