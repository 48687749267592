import { createAction } from 'redux-actions'
import * as constants from './actionTypes'
import { getMetaWithActionLoading } from '@common/sagaCreator/utils'

export const fetchUser = createAction(constants.FETCH_USER)
export const setUser = createAction(constants.SET_USER)

export const setIsFirstTimeUser = createAction(constants.SET_IS_FIRST_TIME_USER)
export const setIsFirstTimeUserSuccess = createAction(constants.SET_IS_FIRST_TIME_USER_SUCCESS)

export const fetchUsersList = createAction(constants.FETCH_USERS_LIST)
export const setUsersList = createAction(constants.SET_USERS_LIST)

export const setTeamsList = createAction(constants.SET_TEAMS_LIST)
export const fetchTeamsList = createAction(constants.FETCH_TEAMS_LIST)
export const setTeamData = createAction(constants.SET_TEAM_DATA)

export const setKnowledgeCenterVisibilityStatus = createAction(constants.SET_KNOWLEDGE_CENTER_VISIBILITY_STATUS)
export const setKnowledgeCenterVisibilityStatusSuccess = createAction(
  constants.SET_KNOWLEDGE_CENTER_VISIBILITY_STATUS_SUCCESS,
)

export const setSelectedTeamIds = createAction(constants.SET_SELECTED_TEAM_IDS)
export const setSelectedTeamId = createAction(constants.SET_SELECTED_TEAM_ID)

export const fetchLibs = createAction(constants.FETCH_LIBRARIES)
export const setLibs = createAction(constants.SET_LIBRARIES)

export const fetchLibsV2 = createAction(constants.FETCH_LIBRARIES_V2)
export const setLibsV2 = createAction(constants.SET_LIBRARIES_V2)

// V2 linked libraries by team
export const fetchTeamLinkedLibraries = createAction(constants.FETCH_TEAM_LINKED_LIBRARIES)
export const setTeamLinkedLibraries = createAction(constants.SET_TEAM_LINKED_LIBRARIES)

export const fetchConfig = createAction(constants.FETCH_CONFIG)
export const setConfig = createAction(constants.SET_CONFIG)

export const fetchInitialData = createAction(constants.FETCH_INITIAL_CLIENT_DATA)
export const fetchInitialDataSuccess = createAction(constants.FETCH_INITIAL_CLIENT_DATA_SUCCESS)
export const setInitialDataLoaded = createAction(constants.SET_INITIAL_CLIENT_DATA_LOADED)

// files management
export const downloadFile = createAction(constants.DOWNLOAD_FILE)
export const uploadFile = createAction(constants.UPLOAD_FILE)
export const deleteFile = createAction(constants.DELETE_FILE)
export const setAbortController = createAction(constants.SET_ABORT_CONTROLLER)
export const cancelUploadingFetch = createAction(constants.CANCEL_UPLOADING_FETCH)
// ---

export const setCompanyALogo = createAction(constants.SET_COMPANY_A_LOGO)
export const setCompanyBLogo = createAction(constants.SET_COMPANY_B_LOGO)

export const setPermissions = createAction(constants.SET_PERMISSIONS)
export const setRole = createAction(constants.SET_ROLE)
export const setRoles = createAction(constants.SET_ROLES)

export const setLoader = createAction(constants.SET_LOADER)
export const showWarningMessage = createAction(constants.SHOW_WARNING_MESSAGE)
export const fetchDictionaries = createAction(constants.FETCH_DICTIONARIES)
export const setDictionaries = createAction(constants.SET_DICTIONARIES)

//change name on deliverableStatuses
export const fetchTeamDeliverableStatus = createAction(
  constants.FETCH_TEAM_DELIVERABLE_STATUS,
  null,
  getMetaWithActionLoading,
)
export const setTeamDeliverableStatus = createAction(constants.SET_TEAM_DELIVERABLE_STATUS)

export const setPdfMode = createAction(constants.SET_PDF_MODE)
export const setCachedLogos = createAction(constants.SET_CACHED_LOGOS)

export const setSnapshotMinDate = createAction(constants.SET_SNAPSHOT_MIN_DATE)
export const getSnapshotMinDate = createAction(constants.GET_SNAPSHOT_MIN_DATE)

// Team Value Capture Toggles
export const setMirroringInitiativesVisibilityStatus = createAction(
  constants.SET_MIRRORING_INITIATIVE_VISIBILITY_STATUS,
)
export const setFTEDashboardVisibilityStatus = createAction(constants.SET_FTE_DASHBOARD_VISIBILITY_STATUS)
export const setSharedTargetsVisibilityStatus = createAction(constants.SET_SHARED_TARGETS_VISIBILITY_STATUS)
export const setTogglesStatusSuccess = createAction(constants.SET_TOGGLES_STATUS_SUCCESS)
export const setConfidentialFilter = createAction(constants.SET_CONFIDENTIAL_FILTER)

export const fetchToggles = createAction(constants.FETCH_TOGGLES)
export const fetchTogglesReset = createAction(constants.FETCH_TOGGLES_RESET)
export const fetchTogglesSuccess = createAction(constants.FETCH_TOGGLES_SUCCESS)

export const setActionState = createAction(constants.SET_ACTION_STATE)
export const clearActionState = createAction(constants.CLEAR_ACTION_STATE)

export const updateWelcomePopupText = createAction(constants.UPDATE_WELCOME_POPUP_TEXT)
export const logout = createAction(constants.LOGOUT)

export const serverNotResponding = createAction(constants.SERVER_NOT_RESPONDING)

export const toggleChat = createAction(constants.TOGGLE_CHAT)
export const toggleSupportBot = createAction(constants.TOGGLE_SUPPORT_BOT)
export const toggleDocsBot = createAction(constants.TOGGLE_DOCS_BOT)
export const refreshToken = createAction(constants.REFRESH_TOKEN)

export const refreshTokenSuccess = createAction(constants.REFRESH_TOKEN_SUCCESS)

export const setRouterLocation = createAction(constants.SET_ROUTER_LOCATION)
